import { createSlice } from '@reduxjs/toolkit';

export interface ISnackbar {
  severity: 'success' | 'error' | 'warning' | 'info';
  message: string;
  open: boolean;
  autoHideDuration: number;
}

export interface IAlertState {
  title: string;
  message: string;
  severity: 'error' | 'warning' | 'success' | 'info';
  snackbarAlert: ISnackbar;
}

const initialState: IAlertState = {
  title: '',
  message: '',
  severity: 'info',
  snackbarAlert: {
    severity: 'info',
    message: '',
    open: false,
    autoHideDuration: 2000,
  },
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    show: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    hide: (state) => {
      return {
        ...state,
        ...{
          title: '',
          message: '',
          severity: 'info',
        },
      };
    },
    showSnackbar: (state, action) => {
      state.snackbarAlert.open = action.payload.open;
      state.snackbarAlert.severity = action.payload.severity;
      state.snackbarAlert.message = action.payload.message;
    },
    hideSnackbar: (state, action) => {
      state.snackbarAlert.open = false;
      state.snackbarAlert.severity = 'info';
      state.snackbarAlert.message = '';
    },
  },
});

export const { show, hide, showSnackbar, hideSnackbar } = alertSlice.actions;

export default alertSlice.reducer;
