import axios from 'axios';

import { ROOT_URL } from '../../utils/constants';

export interface ILoginPayload {
  username: string;
  password: string;
}

export const getlogin = (data: ILoginPayload) => {
  return axios({
    method: 'POST',
    baseURL: ROOT_URL,
    url: '/api/v1/auth/login',
    data,
  });
};

export const refreshToken = () => {
  return axios({
    method: 'GET',
    baseURL: ROOT_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
    },
    url: '/api/v1/auth/refresh-token',
  });
};
