import { Box, Grid, Typography, Button } from '@mui/material';

import './fallback-page.style.scss';

export default () => {
  const handleRetry = () => {
    window.location.reload();
  };
  return (
    <Box className="page404">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <Grid item xs={12}>
          <Box className="four_zero_four_bg">
            <Typography align="center" variant="h1">
              404
            </Typography>
          </Box>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="h5" align="center">
              Look like you're lost
            </Typography>
            <Typography variant="h5" align="center">
              the page you are looking for not avaible!
            </Typography>
            <Button variant="text" color="warning" onClick={handleRetry}>
              Retry
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
