import { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CardActions,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  TextField,
  Badge,
  Typography,
  useMediaQuery,
} from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import addNotification from 'react-push-notification';

import Transitions from '../../../../theme/extended-components/Transitions';
import NotificationList from './NotificationList';
import MainCard from '../../../ui/cards/MainCard';
import { ROOT_URL } from '../../../../utils/constants';
import { axiosInstance } from '../../../../utils/axios.instance';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#ff6452',
    color: '#ff6452',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const NotificationSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [notificationData, setNotifications] = useState<any>({});
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    //@ts-ignore
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    //@ts-ignore
    if (prevOpen.current === true && open === false) {
      //@ts-ignore
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleChange = (event: any) => {
    if (event?.target.value) setValue(event?.target.value);
  };

  const handleNotificationClick = (notification: any) => {
    const { entity, payload } = notification;

    if (entity == 'JOB') {
      navigate({
        pathname: `channels/postings/notification-${payload.notificationObject.entityId}`,
      });
    } else if (entity == 'CANDIDATE') {
      navigate({
        pathname: 'applicant',
        search: `candidateId=${payload.notificationObject.entityId}`,
      });
    } else {
      navigate({
        pathname: 'notifications',
      });
    }
  };

  useEffect(() => {
    let user: any = localStorage.getItem('user');
    user = JSON.parse(user);
    // const eventSource = new EventSource(
    //   `${ROOT_URL}/api/v1/notification/sse?id=${user.id}`
    // );

    // eventSource.addEventListener('message', (event) => {
    //   const data = JSON.parse(event.data);
    //   const { notification = '' } = data.notifications[0];
    //   const timeOfLastNotification = data.notifications[0].createdAt;
    //   const seconds = dayjs().diff(dayjs(timeOfLastNotification), 'seconds');
    //   if (seconds < 10) {
    //     addNotification({
    //       title: 'HireXL ATS',
    //       message: notification,
    //       duration: 5000,
    //       onClick: () => handleNotificationClick(data.notifications[0]),
    //       native: true,
    //     });
    //   }
    //   setNotifications(data);
    // });

    // eventSource.addEventListener('error', (error) => {
    //   console.error('SSE Error:', error);
    // });

    // return () => {
    //   eventSource.close();
    // };
  }, []);

  const handleMarkAllRead = () => {
    axiosInstance({
      method: 'PUT',
      url: '/api/v1/notification/all-read',
    })
      .then((response) => {
        setNotifications(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Box
        sx={{
          ml: 2,
          mr: 3,
          [theme.breakpoints.down('md')]: {
            mr: 2,
          },
        }}
      >
        <ButtonBase sx={{ borderRadius: '12px' }}>
          <Avatar
            variant="rounded"
            sx={{
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&[aria-controls="menu-list-grow"],&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="inherit"
          >
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
              invisible={!notificationData.noOfNewNotification}
            >
              <NotificationsNoneIcon fontSize="inherit" />
            </StyledBadge>
          </Avatar>
        </ButtonBase>
      </Box>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            position={matchesXs ? 'top' : 'top-right'}
            in={open}
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ pt: 2, px: 2 }}
                      >
                        <Grid item>
                          <Stack direction="row" spacing={2}>
                            <Typography variant="subtitle1">
                              All Notification
                            </Typography>
                            <Chip
                              size="small"
                              label={notificationData.noOfNewNotification}
                              sx={{
                                color: theme.palette.background.default,
                                bgcolor: theme.palette.warning.dark,
                              }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item>
                          <Typography
                            component={Button}
                            onClick={handleMarkAllRead}
                            variant="subtitle2"
                            color="primary"
                          >
                            Mark as all read
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        style={{
                          height: '100%',
                          maxHeight: 'calc(100vh - 205px)',
                          overflowX: 'hidden',
                        }}
                      >
                        {/* <Grid container direction="column" spacing={2}>
                          <Grid item xs={12}>
                            <Box sx={{ px: 2, pt: 0.25 }}>
                              <TextField
                                id="outlined-select-currency-native"
                                select
                                fullWidth
                                value={value}
                                onChange={handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                {status.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </TextField>
                            </Box>
                          </Grid>
                          <Grid item xs={12} p={0}>
                            <Divider sx={{ my: 0 }} />
                          </Grid>
                        </Grid> */}
                        <NotificationList
                          notification={notificationData.notifications}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider />
                  <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                    <Button
                      size="small"
                      disableElevation
                      href="#/notifications"
                    >
                      View All
                    </Button>
                  </CardActions>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default NotificationSection;
