import React from 'react';
import {
  Button,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useAppDispatch } from '../../../../../hooks/redux';
import { selectClient } from '../../../../../redux/features/dashboard/clientSlice';

const ChannelItem = ({ item, level = 1 }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const Icon = item.icon;
  const itemIcon = item?.icon ? (
    <Icon fontSize="1.2rem" />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: 6,
        height: 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'small'}
    />
  );

  const itemHandler = (item: any) => {
    if (item.data.id) {
      dispatch(selectClient(item.data));
    } else {
      dispatch(selectClient({}));
    }
    navigate('/channels/postings');
  };
  return (
    <ListItemButton
      data-component-name="Sidebar"
      disabled={item.disabled}
      sx={{
        borderRadius: '12px',
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: '3px',
        pl: `${level * 24}px`,
      }}
      onClick={() => itemHandler(item)}
    >
      <ListItemIcon
        sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36, fontSize: '1.2rem' }}
      >
        {itemIcon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant="body1"
            color="inherit"
            textTransform="capitalize"
          >
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography variant="caption" display="block" gutterBottom>
              {item.caption}
            </Typography>
          )
        }
      />
    </ListItemButton>
  );
};

export default ChannelItem;
