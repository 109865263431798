import { useState, useRef, MouseEvent } from 'react';
import EmojiPicker from 'emoji-picker-react';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import { Box, IconButton, Popper, ClickAwayListener } from '@mui/material';

type Props = {
  onSetEmoji: Function;
  iconSize?: 'small' | 'medium' | 'large';
};

const EmojiInput = (props: Props) => {
  const { iconSize = 'medium' } = props;
  const [open, setOpen] = useState<boolean>(false);
  const wrapperRef = useRef(null);

  const onEmojiClick = (
    emojiObject: { emoji?: any; unified?: string },
    event: any
  ) => {
    props.onSetEmoji(emojiObject.emoji, emojiObject);
    setOpen(false);
  };

  const handleOpenEmojiPicker = (e: MouseEvent<HTMLButtonElement>) => {
    setOpen((prev) => !prev);
  };

  const handleClose = (e: any) => {
    //@ts-ignore
    if (wrapperRef.current && wrapperRef.current.contains(e.target as Node)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box>
      <IconButton
        onClick={handleOpenEmojiPicker}
        ref={wrapperRef}
        size={iconSize}
      >
        <EmojiEmotionsOutlinedIcon fontSize="inherit" />
      </IconButton>
      <ClickAwayListener onClickAway={handleClose}>
        <Popper
          anchorEl={wrapperRef.current}
          open={open}
          placement="bottom"
          sx={{ zIndex: 1500 }}
        >
          <EmojiPicker onEmojiClick={onEmojiClick} />
        </Popper>
      </ClickAwayListener>
    </Box>
  );
};

export default EmojiInput;
