import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import JobAPI from '../../services/job.service';
import { showSnackbar } from './alertSlice';

type Iloading = 'idle' | 'pending' | 'succeeded' | 'failed';

interface IInitialState {
  dayAssignedJob: {
    loading: Iloading;
    error: '';
    data: any;
  };
}

const initialState: IInitialState = {
  dayAssignedJob: {
    loading: 'idle',
    error: '',
    data: [],
  },
};

export const getTodayAssignedJob = createAsyncThunk(
  'day-planner/fetch-assigned-job',
  async () => {
    return await JobAPI.fetchTodayAssignedJob();
  }
);

export const assignJob = createAsyncThunk(
  'day-planner/assign-job',
  async (payload: { jobId: number }, { dispatch }) => {
    await JobAPI.assignJobForDay(payload.jobId);
    dispatch(getTodayAssignedJob());
    dispatch(
      showSnackbar({
        severity: 'info',
        open: true,
        message: 'Job successfully added to your today To-Do list',
      })
    );
  }
);

export const unassignJob = createAsyncThunk(
  'day-planner/unassign-job',
  async (payload: { jobId: number }, { dispatch }) => {
    await JobAPI.unassignJobForDay(payload.jobId);
    dispatch(getTodayAssignedJob());
    dispatch(
      showSnackbar({
        severity: 'info',
        open: true,
        message: 'Job successfully removed from your today To-Do list',
      })
    );
  }
);

const dayPlannerSlice = createSlice({
  name: 'day-planner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTodayAssignedJob.pending, (state) => {
        state.dayAssignedJob.loading = 'pending';
      })
      .addCase(getTodayAssignedJob.fulfilled, (state, action) => {
        state.dayAssignedJob.loading = 'succeeded';
        state.dayAssignedJob.data = action.payload;
      })
      .addCase(getTodayAssignedJob.rejected, (state) => {
        state.dayAssignedJob.loading = 'failed';
      });
  },
});

export default dayPlannerSlice.reducer;
