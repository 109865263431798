import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  fetchAnalytics,
  fetchDashboardChart,
  fetchRecruiterApplications,
  fetchRecruiterdailyOverview,
  fetchRecruiterOverview,
  fetchSummary,
  fetchDailySummary,
  workspaceOverviewAPI,
} from '../../services/fetch-analytics';
import workspaceAPI from '../../services/workspace.service';
import { fetchJobs } from '../../services/fetch-jobs';
import { RootState } from '../../store';
import { logout } from '../onboarding/loginSlice';

type ILoading = 'idle' | 'pending' | 'succeeded' | 'failed';

export interface IAnalytics {
  recruiterJobs: number;
  applicants: number;
  jobs: number;
  jobsToday: number;
  loading: ILoading;
  pipeline: {
    total: number;
    active: number;
    rejected: number;
    offer: number;
  };
}

export interface IActiveJobsStatus {
  jobs: Array<any>;
  loading: ILoading;
}

export interface IDashboardChart {
  data: {
    weekApplicationTrends: any;
    jobApplications: any;
  };
  loading: ILoading;
}

export interface IRecruiterUpload {
  data: [];
  loading: ILoading;
}

export interface IDashboard {
  workspaceOverview: {
    overall: {
      channel: number;
      totalApplication: number;
      sourced: number;
      candidateAdded: number;
      candidateApplied: number;
      activePipeline: number;
      totalRejected: number;
      totalOffer: number;
    };
    todayOverview: Array<{
      // eslint-disable-next-line no-restricted-globals
      name: string;
      'Assigned Jobs': number;
      Sourced: number;
      'Active Pipeline': number;
      Rejected: number;
      Offers: number;
    } | null>;
    loading: ILoading;
  };
  analytics: IAnalytics;
  activeJobs: IActiveJobsStatus;
  charts: IDashboardChart;
  recruiterApplications: IRecruiterUpload;
  recruiterDailyOverview: IRecruiterUpload;
  recruiterOverview: {
    data: {
      overview: {
        assignedJobs: 0;
        sourced: 0;
        pipeline: 0;
        rejection: 0;
        offer: 0;
      };
      channelsOverview: Array<{
        channel: string;
        total: number;
        pipeline: number;
        rejected: number;
        offer: number;
      }>;
    };
    loading: ILoading;
  };
  workSummary: {
    data: {
      channels: string | number;
      totalJobs: string | number;
      activeJobs: string | number;
      submittedApplicants: string | number;
      rejectedApplicants: string | number;
      offers: string | number;
    };
    loading: ILoading;
  };
  dailySummary: {
    data: {
      assignedJobs: number;
      offer: number;
      rejected: number;
      submitted: number;
      userWorkOnJob: Array<{
        job: string;
        sourced: number;
        submitted: number;
        offer: number;
        rejected: number;
      }>;
    };
    loading: ILoading;
  };
  leaderboard: {
    loading: ILoading;
    data:
      | Array<{
          id: number;
          name: string;
          email: string;
          totalOffer: number;
          submitted: number;
          job: number;
          weight: number;
        }>
      | [];
  };
}

const initialState: IDashboard = {
  workspaceOverview: {
    overall: {
      channel: 0,
      totalApplication: 0,
      sourced: 0,
      candidateAdded: 0,
      candidateApplied: 0,
      activePipeline: 0,
      totalRejected: 0,
      totalOffer: 0,
    },
    todayOverview: [],
    loading: 'idle',
  },
  analytics: {
    recruiterJobs: 0,
    applicants: 0,
    jobs: 0,
    jobsToday: 0,
    loading: 'idle',
    pipeline: {
      total: 0,
      active: 0,
      rejected: 0,
      offer: 0,
    },
  },
  activeJobs: {
    jobs: [],
    loading: 'idle',
  },
  charts: {
    data: {
      weekApplicationTrends: [],
      jobApplications: [],
    },
    loading: 'idle',
  },
  recruiterApplications: {
    data: [],
    loading: 'idle',
  },
  recruiterDailyOverview: {
    data: [],
    loading: 'idle',
  },
  recruiterOverview: {
    data: {
      overview: {
        assignedJobs: 0,
        sourced: 0,
        pipeline: 0,
        rejection: 0,
        offer: 0,
      },
      channelsOverview: [],
    },
    loading: 'idle',
  },
  workSummary: {
    data: {
      channels: 0,
      totalJobs: 0,
      activeJobs: 0,
      submittedApplicants: 0,
      rejectedApplicants: 0,
      offers: 0,
    },
    loading: 'idle',
  },
  dailySummary: {
    data: {
      assignedJobs: 0,
      offer: 0,
      rejected: 0,
      submitted: 0,
      userWorkOnJob: [],
    },
    loading: 'idle',
  },
  leaderboard: {
    data: [],
    loading: 'idle',
  },
};

export const dashboardAnalytics = createAsyncThunk(
  'dashboard/analytics',
  async (id: number) => {
    const response = await fetchAnalytics(id);
    return response.data;
  }
);

export const recruiterUploaded = createAsyncThunk(
  'dashboard/recruiter-applications',
  async (_, { getState }) => {
    const { client: { selectedClient: { id = '' } = {} } = {} } =
      getState() as RootState;
    const response = await fetchRecruiterApplications(Number(id));
    return response.data;
  }
);

export const recruiterDailyOverviewThunk = createAsyncThunk(
  'dashboard/recruiter-daily-overview',
  async (_, { getState }) => {
    const { client: { selectedClient: { id = '' } = {} } = {} } =
      getState() as RootState;
    const response = await fetchRecruiterdailyOverview(Number(id));
    return response.data;
  }
);

export const getRecruiterOverview = createAsyncThunk(
  'dashboard/recruiter-overview',
  async (recruiterId: number) => {
    const response = await fetchRecruiterOverview(Number(recruiterId));
    return response.data;
  }
);

export const dashboardActiveJobs = createAsyncThunk(
  'dashboard/activejobs',
  async (_, { getState }) => {
    try {
      const { client: { selectedClient: { id = '' } = {} } = {}, app } =
        getState() as RootState;
      //@ts-ignore
      const workspaceId = app.workspace.id;
      const response = await fetchJobs(1, workspaceId, Number(id));
      return response.data;
    } catch (error) {
      console.log('err', error);
      return [];
    }
  }
);

export const dashboardCharts = createAsyncThunk(
  'dashboard/charts',
  async (cientId: number, { getState }) => {
    try {
      const { app } = getState() as RootState;
      //@ts-ignore
      const workspaceId = app.workspace.id;
      const response = await fetchDashboardChart(
        Number(workspaceId),
        Number(cientId)
      );
      return response.data;
    } catch (error) {
      console.log('err', error);
      return [];
    }
  }
);

export const fetchWorkSummary = createAsyncThunk(
  'dashboard/work-summary',
  async (time: string = '1y') => {
    try {
      const response = await fetchSummary(time);
      return response.data;
    } catch (error) {
      console.log('err', error);
      return [];
    }
  }
);

export const dailySummary = createAsyncThunk(
  'dashboard/daily-summary',
  async (payload: { time: string; clientId: number }, { getState }) => {
    try {
      const response = await fetchDailySummary(
        Number(payload.clientId),
        payload.time
      );
      return response.data;
    } catch (error) {
      console.log('err', error);
      return [];
    }
  }
);

export const workspaceOverview = createAsyncThunk(
  'dashboard/workspace-overview',
  async (timeRange: string | undefined, { getState }) => {
    try {
      const { app } = getState() as RootState;
      //@ts-ignore
      const workspaceId = app.workspace.id;
      const response = await workspaceOverviewAPI(
        Number(workspaceId),
        timeRange
      );
      return response.data;
    } catch (error) {
      console.log('err', error);
      return [];
    }
  }
);

export const workspaceLeaderboard = createAsyncThunk(
  'dashboard/fetch-leaderboard',
  async () => {
    try {
      const response = await workspaceAPI.fetchLeaderBoard();
      return response.data;
    } catch (error) {
      return [];
    }
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(dashboardAnalytics.pending, (state) => {
        state.analytics.loading = 'pending';
      })
      .addCase(dashboardAnalytics.fulfilled, (state, action) => {
        state.analytics = { ...action.payload, loading: 'succeeded' };
      })
      .addCase(dashboardAnalytics.rejected, (state) => {
        state.analytics.loading = 'failed';
      })
      .addCase(dashboardActiveJobs.pending, (state) => {
        state.activeJobs.loading = 'pending';
      })
      .addCase(dashboardActiveJobs.fulfilled, (state, action) => {
        state.activeJobs.jobs = action.payload;
        state.activeJobs.loading = 'succeeded';
      })
      .addCase(dashboardActiveJobs.rejected, (state) => {
        state.activeJobs.loading = 'failed';
      })
      .addCase(dashboardCharts.pending, (state) => {
        state.charts.loading = 'pending';
      })
      .addCase(dashboardCharts.fulfilled, (state, action) => {
        state.charts.data = action.payload;
        state.charts.loading = 'succeeded';
      })
      .addCase(dashboardCharts.rejected, (state) => {
        state.charts.loading = 'failed';
      })
      .addCase(recruiterUploaded.pending, (state) => {
        state.recruiterApplications.loading = 'pending';
      })
      .addCase(recruiterUploaded.fulfilled, (state, action) => {
        state.recruiterApplications.data = action.payload;
        state.recruiterApplications.loading = 'succeeded';
      })
      .addCase(recruiterUploaded.rejected, (state) => {
        state.recruiterApplications.loading = 'failed';
      })
      .addCase(recruiterDailyOverviewThunk.pending, (state) => {
        state.recruiterDailyOverview.loading = 'pending';
      })
      .addCase(recruiterDailyOverviewThunk.fulfilled, (state, action) => {
        state.recruiterDailyOverview.data = action.payload;
        state.recruiterDailyOverview.loading = 'succeeded';
      })
      .addCase(recruiterDailyOverviewThunk.rejected, (state) => {
        state.recruiterDailyOverview.loading = 'failed';
      })
      .addCase(getRecruiterOverview.pending, (state) => {
        state.recruiterOverview.loading = 'pending';
      })
      .addCase(getRecruiterOverview.fulfilled, (state, action) => {
        state.recruiterOverview.data = action.payload;
        state.recruiterOverview.loading = 'succeeded';
      })
      .addCase(getRecruiterOverview.rejected, (state) => {
        state.recruiterOverview.loading = 'failed';
      })
      .addCase(fetchWorkSummary.pending, (state) => {
        state.workSummary.loading = 'pending';
      })
      .addCase(fetchWorkSummary.fulfilled, (state, action) => {
        state.workSummary.data = action.payload;
        state.workSummary.loading = 'succeeded';
      })
      .addCase(fetchWorkSummary.rejected, (state) => {
        state.workSummary.loading = 'failed';
      })
      .addCase(dailySummary.pending, (state) => {
        state.dailySummary.loading = 'pending';
      })
      .addCase(dailySummary.fulfilled, (state, action) => {
        state.dailySummary.data = action.payload;
        state.dailySummary.loading = 'succeeded';
      })
      .addCase(dailySummary.rejected, (state) => {
        state.dailySummary.loading = 'failed';
      })
      .addCase(workspaceOverview.pending, (state) => {
        state.workspaceOverview.loading = 'pending';
      })
      .addCase(workspaceOverview.fulfilled, (state, action) => {
        state.workspaceOverview.overall = action.payload.workspaceOverview;
        state.workspaceOverview.todayOverview =
          action.payload.workspaceTodayOverview;
        state.workspaceOverview.loading = 'succeeded';
      })
      .addCase(workspaceOverview.rejected, (state) => {
        state.workspaceOverview.loading = 'failed';
      })
      .addCase(workspaceLeaderboard.rejected, (state) => {
        state.leaderboard.loading = 'failed';
      })
      .addCase(workspaceLeaderboard.fulfilled, (state, action) => {
        state.leaderboard.loading = 'succeeded';
        state.leaderboard.data = action.payload;
      })
      .addCase(workspaceLeaderboard.pending, (state) => {
        state.leaderboard.loading = 'pending';
      })
      .addCase(logout, (state) => {
        state.workspaceOverview = {
          overall: {
            channel: 0,
            totalApplication: 0,
            sourced: 0,
            candidateAdded: 0,
            candidateApplied: 0,
            activePipeline: 0,
            totalRejected: 0,
            totalOffer: 0,
          },
          todayOverview: [],
          loading: 'idle',
        };
        state.analytics = {
          recruiterJobs: 0,
          applicants: 0,
          jobs: 0,
          jobsToday: 0,
          loading: 'idle',
          pipeline: {
            total: 0,
            active: 0,
            rejected: 0,
            offer: 0,
          },
        };
        state.activeJobs = {
          jobs: [],
          loading: 'idle',
        };
        state.charts = {
          data: {
            weekApplicationTrends: [],
            jobApplications: [],
          },
          loading: 'idle',
        };
        state.recruiterApplications = {
          data: [],
          loading: 'idle',
        };
        state.recruiterDailyOverview = {
          data: [],
          loading: 'idle',
        };
        state.recruiterOverview = {
          data: {
            overview: {
              assignedJobs: 0,
              sourced: 0,
              pipeline: 0,
              rejection: 0,
              offer: 0,
            },
            channelsOverview: [],
          },
          loading: 'idle',
        };
        state.workSummary = {
          data: {
            channels: 0,
            totalJobs: 0,
            activeJobs: 0,
            submittedApplicants: 0,
            rejectedApplicants: 0,
            offers: 0,
          },
          loading: 'idle',
        };
        state.dailySummary = {
          data: {
            assignedJobs: 0,
            offer: 0,
            rejected: 0,
            submitted: 0,
            userWorkOnJob: [],
          },
          loading: 'idle',
        };
        state.leaderboard = {
          data: [],
          loading: 'idle',
        };
      });
  },
});

export default dashboardSlice.reducer;
