import axios from 'axios';
import moment from 'moment';

export const parseableAxiosInstance = axios.create({});

parseableAxiosInstance.interceptors.request.use(
  (config) => {
    // Alternatively, add to request body
    if (config.method === 'post' || config.method === 'put') {
      let user: any = localStorage.getItem('profile');
      if (user) {
        user = JSON.parse(user);
        const userCreatedDate = user.createdAt
          ? moment(user.createdAt).format('DD-MM-YYYY')
          : null;
        config.data = {
          ...config.data,
          user: user.id,
          createdOn: userCreatedDate,
          //@ts-ignore
          host: parseableAxiosInstance.defaults.ipAddress || null,
        };
      }
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
