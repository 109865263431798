import { axiosInstance } from '../../utils/axios.instance';
import { ROOT_URL } from '../../utils/constants';

export const fetchWorkspaceClients = (workspace: any) =>
  axiosInstance({
    method: 'GET',
    baseURL: ROOT_URL,
    url: '/api/v1/channel/workspace-client',
    params: {
      workspace,
    },
  });

export const addClient = (data: any) => {
  return axiosInstance({
    method: 'POST',
    baseURL: ROOT_URL,
    url: '/api/v1/channel/create',
    data,
  });
};

export const updateClient = (data: any, clientId: number) => {
  return axiosInstance({
    method: 'PUT',
    baseURL: ROOT_URL,
    url: `/api/v1/channel/update/${clientId}`,
    data,
  });
};
