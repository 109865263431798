import React from 'react';
import { Alert, Snackbar, SnackbarCloseReason } from '@mui/material';

import { useAppSelector } from '../../hooks/redux';
import { useAppDispatch } from '../../hooks/redux';
import { hide } from '../../redux/features/dashboard/alertSlice';

const AlertContainer = () => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const {
    title = '',
    message = '',
    severity = 'info',
  } = useAppSelector((state) => state.alert);

  React.useEffect(() => {
    if (message.length) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [message]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hide());
  };

  return (
    <Snackbar
      open={message.length > 0}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
    >
      <Alert
        severity={severity}
        variant="filled"
        sx={{ width: '100%' }}
        onClose={handleClose}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertContainer;
