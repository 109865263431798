import { TableCell, tableCellClasses } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.8rem',
  },
}));

export default StyledTableCell;
