import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  fetchCitiesList,
  fetchPrimaryFunctionalArea,
  fetchSecondaryFunctionalArea,
  fetchStackholders,
  createOpening,
  updateOpening,
} from '../../services/jobs';
import { RootState } from '../../store';

type loadingType = 'idle' | 'pending' | 'succeeded' | 'failed';

export interface JobPostingState {
  jobResponse: {
    loading: loadingType;
    data: any;
  };
  functionalArea: {
    loading: loadingType;
    list: Array<{
      id: number;
      name: string;
    }>;
  };
  secondaryFunctionalArea: {
    loading: loadingType;
    list: Array<{
      id: number;
      name: string;
    }>;
  };
  cities: {
    loading: loadingType;
    list: Array<{
      id: number;
      name: string;
      state: number;
    }>;
  };
  stackholders: {
    loading: loadingType;
    list: Array<{
      email: string;
      id: number;
      name?: string;
      enabled: boolean;
    }>;
  };
}

const initialState: JobPostingState = {
  jobResponse: {
    loading: 'idle',
    data: {},
  },
  functionalArea: {
    loading: 'idle',
    list: [],
  },
  secondaryFunctionalArea: {
    loading: 'idle',
    list: [],
  },
  cities: {
    loading: 'idle',
    list: [],
  },
  stackholders: {
    loading: 'idle',
    list: [],
  },
};

export const jobFunctionalArea = createAsyncThunk(
  'jobPosting/fetchFunctionalArea',
  async (_, { getState }) => {
    const { app }: any = getState() as RootState;
    const response = await fetchPrimaryFunctionalArea(app.workspace.id);
    return response.data;
  }
);

export const jobSecondaryFunctionalArea = createAsyncThunk(
  'jobPosting/fetchSecondaryFunctionalArea',
  async (id: number) => {
    const response = await fetchSecondaryFunctionalArea(id);
    return response.data;
  }
);

export const jobStackholders = createAsyncThunk(
  'jobPosting/fetchStackhoders',
  async (_, { getState }) => {
    const { client, app } = getState() as RootState;
    let clientId = null;
    if (client?.selectedClient) {
      clientId = client.selectedClient.id;
    }
    //@ts-ignore
    const response = await fetchStackholders(app.workspace.id, clientId);
    return response.data;
  }
);

export const addOpening = createAsyncThunk(
  'jobPosting/addOpening',
  async (job: any, { getState }) => {
    const { client, app } = getState() as RootState;
    //@ts-ignore
    const activeWorkpsaceId = app.workspace.id;
    const activeClientId = client.selectedClient?.id;
    const response = await createOpening({
      ...job,
      workspaceId: activeWorkpsaceId,
      channelId: activeClientId,
    });
    return response.data;
  }
);

export const editOpening = createAsyncThunk(
  'jobPosting/editOpening',
  async ({ id, job }: any, { getState }) => {
    const { client, app } = getState() as RootState;
    //@ts-ignore
    const activeWorkpsaceId = app.workspace.id;
    if (activeWorkpsaceId) {
      const response = await updateOpening(id, job, activeWorkpsaceId);
      return response.data;
    }
  }
);

export const jobCities = createAsyncThunk('jobPosting/cities', async () => {
  const response = await fetchCitiesList();
  return response.data;
});

export const jobPostingSlice = createSlice({
  name: 'jobPosting',
  initialState,
  reducers: {
    resetJobResponse: (state) => {
      state.jobResponse.loading = 'idle';
      state.jobResponse.data = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(jobFunctionalArea.pending, (state) => {
        state.functionalArea.loading = 'pending';
      })
      .addCase(jobFunctionalArea.fulfilled, (state, action) => {
        state.functionalArea.loading = 'succeeded';
        state.functionalArea.list = action.payload;
      })
      .addCase(jobFunctionalArea.rejected, (state) => {
        state.functionalArea.loading = 'failed';
      })
      .addCase(jobSecondaryFunctionalArea.pending, (state) => {
        state.secondaryFunctionalArea.loading = 'pending';
      })
      .addCase(jobSecondaryFunctionalArea.fulfilled, (state, action) => {
        state.secondaryFunctionalArea.loading = 'succeeded';
        state.secondaryFunctionalArea.list = action.payload;
      })
      .addCase(jobSecondaryFunctionalArea.rejected, (state) => {
        state.secondaryFunctionalArea.loading = 'failed';
      })
      .addCase(jobStackholders.pending, (state) => {
        state.stackholders.loading = 'pending';
      })
      .addCase(jobStackholders.fulfilled, (state, action) => {
        state.stackholders.loading = 'succeeded';
        state.stackholders.list = action.payload;
      })
      .addCase(jobStackholders.rejected, (state) => {
        state.stackholders.loading = 'failed';
      })
      .addCase(jobCities.pending, (state) => {
        state.cities.loading = 'pending';
      })
      .addCase(jobCities.fulfilled, (state, action) => {
        state.cities.loading = 'succeeded';
        state.cities.list = action.payload;
      })
      .addCase(jobCities.rejected, (state) => {
        state.cities.loading = 'failed';
      })
      .addMatcher(isAnyOf(addOpening.pending, editOpening.pending), (state) => {
        state.jobResponse.loading = 'pending';
      })
      .addMatcher(
        isAnyOf(addOpening.fulfilled, editOpening.fulfilled),
        (state, action) => {
          state.jobResponse.loading = 'succeeded';
          state.jobResponse.data = action.payload;
        }
      )
      .addMatcher(
        isAnyOf(addOpening.rejected, editOpening.rejected),
        (state, action) => {
          state.jobResponse.loading = 'failed';
        }
      );
  },
});

export const { resetJobResponse } = jobPostingSlice.actions;

export default jobPostingSlice.reducer;

export const getStakeholderOptions = (state: RootState) => {
  const { loading, list } = state.jobPosting.stackholders;
  if (loading === 'succeeded') {
    return list.map((stakeholder) => ({
      label: stakeholder.name,
      value: stakeholder.id,
    }));
  }
  return [];
};
