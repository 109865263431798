import { axiosInstance } from '../../utils/axios.instance';

export const fetchToDo = () => axiosInstance.get('/api/v1/todo');

export const addToDo = (todoItem: any) =>
  axiosInstance.post('/api/v1/todo', todoItem);

export const updateToDo = (todoItem: any) =>
  axiosInstance.put('/api/v1/todo', todoItem);

export const deleteToDo = (todoItem: any) =>
  axiosInstance({
    method: 'DELETE',
    url: '/api/v1/todo',
    data: todoItem,
  });
