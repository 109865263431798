/**
 * Description: App Wrapper
 * Author: Ankur Sharma<ankur.sharma@technocube.in>
 */

import { useEffect } from 'react';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ConfirmProvider } from 'material-ui-confirm';
import { GoogleOAuthProvider } from '@react-oauth/google';

import ThemeProvider from './theme';
import GlobalStyles from './theme/globalStyles';
import Routes from './routes';
import AlertContainer from './components/app-alerts';
import useGlobalEventListener from './hooks/useGlobalEventListener';

const App = () => {
  useGlobalEventListener();
  useEffect(() => {
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification');
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(function (permission) {
        if (permission === 'granted') {
          console.log('Permission granted');
        }
      });
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <GoogleOAuthProvider clientId="704592404209-a7fm5k5kg5vsr7o58ba0tork7rnevge6.apps.googleusercontent.com">
        <ThemeProvider>
          <GlobalStyles />
          <ConfirmProvider>
            <CssBaseline />
            <AlertContainer />
            <Routes />
          </ConfirmProvider>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </StyledEngineProvider>
  );
};

export default App;
