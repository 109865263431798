import { axiosInstance } from '../../utils/axios.instance';

export const apiGetEvaluationTemplate = (jobId: number | null) => {
  return axiosInstance({
    method: 'GET',
    url: `/api/v1/job-applicant-evaluation/template/${jobId}`,
  });
};

export const apiCreateEvaluationTemplate = (data: any) => {
  return axiosInstance({
    method: 'POST',
    url: '/api/v1/job-applicant-evaluation/template',
    data,
  });
};

export const apiUpdateEvaluationTemplate = (templateId: number, data: any) => {
  return axiosInstance({
    method: 'PUT',
    url: `/api/v1/job-applicant-evaluation/template?id=${templateId}`,
    data,
  });
};

export const apiDeleteEvaluationTemplate = (templateId: number) => {
  return axiosInstance({
    method: 'DELETE',
    url: `/api/v1/job-applicant-evaluation/template?id=${templateId}`,
  });
};

export const apiCreateObservation = (data: any) => {
  return axiosInstance({
    method: 'POST',
    url: '/api/v1/job-applicant-evaluation/recruiter-observation',
    data,
  });
};

export const apiGetObservation = (applicationId: any) => {
  return axiosInstance({
    method: 'GET',
    url: `/api/v1/job-applicant-evaluation/recruiter-observation/${applicationId}`,
  });
};
