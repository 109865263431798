import { useState, useEffect } from 'react';

import {
  Typography,
  Table,
  TableCell,
  tableCellClasses,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Stack,
  Avatar,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';

import { useAppSelector } from '../../hooks/redux';
import WorkspaceAPI from '../../redux/services/workspace.service';

const StyledTableCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const defaultListState = {
  isLoading: true,
  list: [],
};

export default () => {
  const [invitedUsersState, setInvitedUserState] = useState(defaultListState);
  const { app }: any = useAppSelector((state) => state);

  const fetchUsers = (workspaceId: any) => {
    WorkspaceAPI.getWorkspaceInvitation(workspaceId)
      .then(({ data }) => {
        setInvitedUserState({
          list: data,
          isLoading: false,
        });
      })
      .catch((err) => {
        setInvitedUserState((prev) => {
          return {
            ...prev,
            isLoading: false,
          };
        });
      });
  };

  useEffect(() => {
    setInvitedUserState({
      list: [],
      isLoading: true,
    });
    fetchUsers(app.workspace.id);
  }, [app.workspace.id]);

  const { list = [] } = invitedUsersState;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((user: any) => {
            return (
              <StyledTableRow key={`candidate-${user.id}`} hover>
                <StyledTableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {user.name}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <LocalPostOfficeIcon
                      color="primary"
                      fontSize="inherit"
                      sx={{ mr: 1 }}
                    />
                    {user.email}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography
                    variant="body2"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {user.isAccepted
                      ? 'Invitation Accepted'
                      : 'Invitation Pending'}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
