import { ChangeEvent, useState } from 'react';
import {
  Card,
  CardContent,
  Box,
  Stack,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  FormHelperText,
} from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';

import UploadResume from '../AddSingleCandidate/UploadResume.react';
import { axiosInstance } from '../../utils/axios.instance';
import CSVPreview from './CSVPreview.react';

import { showSnackbar } from '../../redux/features/dashboard/alertSlice';

const PapaParse = require('papaparse/papaparse.min.js');

interface State {
  jobTitle: string;
  file: any;
  filetype: any;
  errors: string;
  isSubmitting: boolean;
  CSVPreview: Array<Object>;
}

type Props = any;

const defaultState: State = {
  jobTitle: '',
  file: '',
  filetype: 'csv',
  errors: '',
  isSubmitting: false,
  CSVPreview: [],
};

const AddMultipleCandidate = (props: Props) => {
  const dispatch = useAppDispatch();
  const [values, setValues] = useState(defaultState);
  const { id } = useAppSelector((state) => state.uploadApplicant.selectedJob);
  const [fileProgress, setFileProgress] = useState({
    isSubmitting: false,
    progress: 0,
  });

  const resetEverything = () => {
    setFileProgress({
      isSubmitting: false,
      progress: 0,
    });
    setValues(defaultState);
  };

  const parseCSV = (file: any) => {
    if (file.name.split('.').pop() === 'csv') {
      PapaParse.parse(file ? file : values.file, {
        complete: (result: any) => {
          setValues({ ...values, file, CSVPreview: result.data });
        },
      });
    } else {
      setValues({ ...values, CSVPreview: [] });
    }
  };

  const handleChange = (file: any) => {
    if (values.filetype !== file.name.split('.').pop()) {
      setValues({
        ...values,
        file,
        errors: `Please select the correct filetype i.e ${values.filetype}`,
      });
      return;
    } else if (values.filetype === file.name.split('.').pop() && 'csv') {
      parseCSV(file);
    }
    setValues({ ...values, file, errors: '' });
  };

  const handleSubmit = () => {
    if (!values.filetype) {
      setValues({ ...values, errors: 'Please select the filetype' });
    } else if (values.file === '') {
      setValues({ ...values, errors: 'Please select a file to be uploaded' });
    } else {
      if (values.filetype === values.file.name.split('.').pop()) {
        setValues({ ...values, isSubmitting: true });
        const formData: any = new FormData();
        formData.append('file', values.file);
        formData.append('job', id);
        axiosInstance.defaults.headers['content-type'] = 'multipart/form-data';

        axiosInstance({
          method: 'POST',
          url: '/api/v1/job-application/upload',
          data: formData,
          onUploadProgress: ({
            loaded,
            total,
            progress = 0,
            bytes,
            estimated,
            rate,
            upload = true,
          }) => {
            setFileProgress({
              isSubmitting: true,
              progress: Math.round(progress * 100),
            });
          },
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(({ data }) => {
            dispatch(
              showSnackbar({
                open: true,
                message: data.message,
                severity: 'success',
              })
            );
            resetEverything();
          })
          .catch((err) => {
            if (err.response) {
              dispatch(
                showSnackbar({
                  open: true,
                  message: 'something went wrong!',
                  severity: 'error',
                })
              );
            } else {
              dispatch(
                showSnackbar({
                  open: true,
                  message: 'Unknown error has occurred!',
                  severity: 'error',
                })
              );
            }
            resetEverything();
          });
        axiosInstance.defaults.headers['content-type'] = 'application/json';
      } else {
        setValues({ ...values, errors: 'Please select the correct filetype' });
      }
    }
  };

  const handleFileTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, filetype: e.target.value });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      component="form"
      onSubmit={handleSubmit}
    >
      <Card>
        <CardContent>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body1">Bulk import candidates for</Typography>
            <Typography variant="h6">{props.job.title}</Typography>
          </Stack>
          <Box mt={2}>
            <FormControl>
              <FormLabel id="file-upload-types">File upload type</FormLabel>
              <RadioGroup
                aria-labelledby="file-upload-types"
                defaultValue="csv"
                row
                onChange={handleFileTypeChange}
                name="file-radio-buttons-group"
              >
                <FormControlLabel
                  value="csv"
                  control={<Radio />}
                  label="Upload a CSV file"
                />
                <FormControlLabel
                  value="zip"
                  control={<Radio />}
                  label="Upload CV files (Upload ZIP file)"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <UploadResume
            handleFileUpload={handleChange}
            filename={values.file.name}
            fileProgress={fileProgress}
          />
          {values.CSVPreview.length && values.filetype === 'csv' ? (
            <Box>
              <CSVPreview data={values.CSVPreview} />
            </Box>
          ) : (
            <noscript />
          )}

          <Box py={2}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Upload Candidates
            </Button>
            <FormHelperText error>{values.errors}</FormHelperText>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AddMultipleCandidate;
