import { FC } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  Typography,
  Chip,
} from '@mui/material';
import {
  stringAvatar,
  stringToColor,
} from '../../../../utils/helper-functions';

const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  '&:hover': {
    background: theme.palette.primary.light,
  },
  '& .MuiListItem-root': {
    padding: 0,
  },
}));

interface NotificationItemsProps {
  notification: {
    payload: {
      notificationObject: {
        notificationChange: Array<{
          actor: any;
          actorId: number;
          notificationObjectId: number;
          id: number;
        }>;
        entityId: number;
      };
    };
    notification: string;
    isRead: boolean;
    entity: 'JOB' | 'CANDIDATE' | 'RECRUITER' | 'CALENDAR';
    createdAt: string;
  };
}

const NotificationItem: FC<NotificationItemsProps> = ({ notification }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const chipSX = {
    height: 24,
    padding: '0 6px',
  };

  const chipErrorSX = {
    ...chipSX,
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[100],
    marginRight: '5px',
  };

  const chipWarningSX = {
    ...chipSX,
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.warning.light,
  };

  const handleClick = () => {
    const { entity, payload } = notification;
    if (entity == 'JOB') {
      navigate({
        pathname: `channels/postings/notification-${payload.notificationObject.entityId}`,
      });
    }
    if (entity == 'CANDIDATE') {
      navigate({
        pathname: 'applicant',
        search: `candidateId=${payload.notificationObject.entityId}`,
      });
    }
  };

  return (
    <ListItemWrapper onClick={handleClick}>
      <ListItem alignItems="center">
        <ListItemAvatar>
          <Avatar
            alt={
              notification?.payload?.notificationObject?.notificationChange[0]
                ?.actor?.name
            }
            sx={{
              bgcolor: stringToColor(
                notification?.payload?.notificationObject?.notificationChange[0]
                  ?.actor?.name
              ),
            }}
            {...stringAvatar(
              notification?.payload?.notificationObject?.notificationChange[0]
                ?.actor?.name
            )}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            notification?.payload?.notificationObject?.notificationChange[0]
              ?.actor?.name
          }
        />
        <ListItemSecondaryAction>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12}>
              <Typography variant="caption" display="block" gutterBottom>
                {moment(notification.createdAt).fromNow()}
              </Typography>
            </Grid>
          </Grid>
        </ListItemSecondaryAction>
      </ListItem>
      <Grid container direction="column" className="list-container">
        <Grid item xs={12} sx={{ pb: 2 }}>
          <Typography variant="subtitle2">
            {notification.notification}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {!notification.isRead && (
              <Grid item>
                <Chip label="Unread" sx={chipErrorSX} />
              </Grid>
            )}
            {!notification.isRead && (
              <Grid item>
                <Chip label="New" sx={chipWarningSX} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ListItemWrapper>
  );
};

export default NotificationItem;
