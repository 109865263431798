import React, { useEffect } from 'react';

import { Box, Tabs, Tab } from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';

function a11yProps(index: number) {
  return {
    id: `candidate-info-tab-${index}`,
    'aria-controls': `candidate-info-tabpanel-${index}`,
  };
}

interface Props {
  tabList: Array<{
    label: string;
    tabPanel: React.ReactNode;
  }>;
  activeTab: string;
  onChangeActiveTab: (tabValue: string) => void;
}

const CandidateInfoTabs = ({
  tabList = [],
  activeTab,
  onChangeActiveTab,
}: Props) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChangeActiveTab(`${newValue}`);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={activeTab ? Number(activeTab) : 0}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {tabList.map((tab) => (
              <Tab
                key={`tab-label-${tab.label}`}
                label={tab.label}
                {...a11yProps(0)}
              />
            ))}
          </Tabs>
        </Box>
        {tabList.map((tab, index) => (
          <TabPanel
            key={`tabpanel-${tab.label}`}
            value={`${index}`}
            sx={{ paddingX: 0 }}
          >
            {tab.tabPanel}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default CandidateInfoTabs;
