import ListAltIcon from '@mui/icons-material/ListAlt';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import WorkIcon from '@mui/icons-material/Work';
import StorageIcon from '@mui/icons-material/Storage';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import GroupsIcon from '@mui/icons-material/Groups';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import StarIcon from '@mui/icons-material/Star';

const icons = {
  ListAltIcon,
  PlaylistAddIcon,
  PeopleAltIcon,
  MoveToInboxIcon,
  WorkIcon,
  CreateNewFolderIcon,
  StorageIcon,
  GroupsIcon,
  CorporateFareIcon,
  StarIcon,
};

export default {
  id: 'channel',
  title: 'Channel',
  type: 'group',
  children: [
    {
      id: 'all-channel',
      title: 'All Channels',
      type: 'item',
      url: 'channels',
      icon: icons.CorporateFareIcon,
      breadcrumbs: false,
    },
  ],
};
