/**
 * Filename: SuspenseView.react.tsx
 * Description: Handle React Suspense for the component loading
 * Author: Ankur Sharma<ankur.sharma@technocube.in>
 */

import React, { ReactNode, FC, Suspense } from 'react';
import PageLoading from './PageLoading';

interface SuspenseViewProps {
  children?: ReactNode;
}

const SuspenseView: FC<SuspenseViewProps> = ({ children }) => {
  return <Suspense fallback={<PageLoading />}>{children}</Suspense>;
};

export default SuspenseView;
