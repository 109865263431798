import Cookies from 'universal-cookie';
import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'lodash';
import { fetchSession } from '../services/fetch-session';
import WorkspaceChannelAPI from '../services/workspace-channel.service';
import WorkspaceAPI from '../services/workspace.service';
import { logout } from '../features/onboarding/loginSlice';
import { IStakeholder } from '../../types/stakeholder';
import { RootState } from '../store';

const cookies = new Cookies();
const authToken = cookies.get('authToken');

type ILoading = 'idle' | 'pending' | 'succeeded' | 'failed';

// TODO: Seperate appSlice to Global and app wide state containers

export interface IAppState {
  isEmailVerified: boolean;
  token: string;
  permissions: any;
  user: any;
  loading: ILoading;
  error: string;
  workspace: {
    id: number | null | undefined;
    name: string;
    slug: string;
  };
  otherWorkspaces: Array<any>;
  channels: Array<any>;
  email?: string;
  stakeholders: {
    loading: ILoading;
    error: string;
    data: IStakeholder[];
  };
  workspaceStakeholder: {
    loading: ILoading;
    error: string;
    data: IStakeholder[];
  };
}

const initialState: IAppState = {
  isEmailVerified: false,
  token: authToken || '',
  permissions: {},
  user: {},
  loading: 'idle',
  error: '',
  workspace: {
    id: null,
    name: '',
    slug: '',
  },
  otherWorkspaces: [],
  channels: [],
  stakeholders: {
    loading: 'idle',
    error: '',
    data: [],
  },
  workspaceStakeholder: {
    loading: 'idle',
    error: '',
    data: [],
  },
};

export const getSession = createAsyncThunk('user/session', async () => {
  const baseChannel: any | string = localStorage.getItem('baseWorkspace');
  const response = await fetchSession(baseChannel);
  return response.data;
});

export const getWorkspaceChannelStakeholder = createAsyncThunk(
  'app/stakeholders',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const {
      app: { workspace = { id: '' } },
      client: { selectedClient = { id: '' } },
    } = state;
    if (workspace.id && selectedClient.id) {
      return await WorkspaceChannelAPI.getStakeholders(
        Number(workspace.id),
        Number(selectedClient.id)
      );
    }
  }
);

export const getWorkspaceStakeholders = createAsyncThunk(
  'app/workspace-stakeholders',
  async (payload: any) => {
    return WorkspaceAPI.getWorkspaceUsers(payload.id);
  }
);

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    checkUserEmail: (state, action) => {
      return {
        ...state,
        isEmailVerified: action.payload,
      };
    },
    updateUserInfo: (state, action) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    setPermissions: (state, action) => {
      const userPermissions: any = {};
      action.payload.forEach(({ v2 }: any) => {
        userPermissions[v2] = true;
      });
      return {
        ...state,
        permissions: userPermissions,
      };
    },
    addToken: (state, action) => {
      return {
        ...state,
        token: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSession.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getSession.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.workspace = action.payload.workspace;
        state.otherWorkspaces = action.payload.otherWorkspaces;
        state.channels = action.payload.channels;
        const permissions: any = {};
        action.payload.permissions.forEach(({ v2 }: any) => {
          const accessName = v2.split(':')[0];
          return (permissions[accessName] = true);
        });

        state.permissions = permissions;
      })
      .addCase(getSession.rejected, (state) => {
        state.loading = 'failed';
        state.error = 'Something went wrong!';
      })
      .addCase(logout, (state) => {
        state.isEmailVerified = false;
        state.token = '';
        state.permissions = {};
        state.user = {};
        state.loading = 'idle';
        state.error = '';
        state.workspace = { id: null, name: '', slug: '' };
        state.otherWorkspaces = [];
        state.channels = [];
      })
      .addCase(getWorkspaceChannelStakeholder.pending, (state) => {
        state.stakeholders.loading = 'pending';
        state.stakeholders.error = '';
      })
      .addCase(getWorkspaceChannelStakeholder.fulfilled, (state, action) => {
        state.stakeholders.loading = 'succeeded';
        state.stakeholders.error = '';
        if (Array.isArray(action.payload)) {
          state.stakeholders.data = action.payload;
        } else {
          state.stakeholders.data = [];
        }
      })
      .addCase(getWorkspaceChannelStakeholder.rejected, (state) => {
        state.stakeholders.loading = 'failed';
        state.stakeholders.error = 'Something went wrong!';
      })
      .addCase(getWorkspaceStakeholders.pending, (state) => {
        state.workspaceStakeholder.loading = 'pending';
      })
      .addCase(getWorkspaceStakeholders.fulfilled, (state, action) => {
        state.workspaceStakeholder.loading = 'succeeded';
        //@ts-ignore
        state.workspaceStakeholder.data = action.payload;
      })
      .addCase(getWorkspaceStakeholders.rejected, (state, action) => {
        state.workspaceStakeholder.loading = 'failed';
        state.workspaceStakeholder.error = 'something went wrong!';
      });
  },
});

export const { addToken, checkUserEmail, updateUserInfo, setPermissions } =
  appSlice.actions;

export default appSlice.reducer;

export const getStakeholderOptions = createSelector(
  [(state: RootState) => state.app.workspaceStakeholder],
  (workspaceStakeholder) => {
    const { loading, data } = workspaceStakeholder;
    if (loading === 'succeeded') {
      return data.map((stakeholder) => ({
        label: stakeholder.name,
        value: stakeholder.id,
      }));
    }
    return [];
  }
);
