import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

import CandidateAPI from '../../services/candidate.service';
import { RootState } from '../../store';

interface IState {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  data: Array<any>;
  error: string;
}

const fetchCandidateTimeline = createAsyncThunk(
  'candidateTimeline/fetchCandidateTimeline',
  async (candidateId: number) => {
    const response: any = await CandidateAPI.candidateTimeline(candidateId);
    return response;
  }
);

const initialState: IState = {
  loading: 'idle',
  data: [],
  error: '',
};

const candidateTimelineSlice = createSlice({
  name: 'candidateTimeline',
  initialState,
  reducers: {
    resetTimeline: (state) => {
      state.data = [];
      state.loading = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidateTimeline.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchCandidateTimeline.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = 'succeeded';
        state.error = '';
      })
      .addCase(fetchCandidateTimeline.rejected, (state) => {
        state.loading = 'failed';
        state.error = 'Something went wrong!';
      });
  },
});

export default candidateTimelineSlice.reducer;
export const { resetTimeline } = candidateTimelineSlice.actions;
export { fetchCandidateTimeline };

export const getDNPStatus = createSelector(
  [(state: RootState) => state.candidateTimeline],
  (timeline) => {
    const { loading, data = [] } = timeline;
    if (loading === 'succeeded' && data.length) {
      if (data[0].event.eventType.slug == 'dnp') {
        return 'dnp';
      }
      const feedbackEvent = data.filter((feedback: any) =>
        ['feedback-updated', 'feedback-added'].includes(
          feedback.event.eventType.slug
        )
      );
      if (feedbackEvent.length) {
        return 'feedback-added';
      }
    }
    return '';
  }
);

export const candidateStageTimeline = createSelector(
  [(state: RootState) => state.candidateTimeline],
  (timeline) => {
    const { loading, data = [] } = timeline;
    if (loading === 'succeeded' && data.length) {
      return data
        .filter(
          (timeline: any) =>
            timeline.event.eventType.slug == 'stage-change' &&
            timeline.event.metadata.slug
        )
        .map((timeline: any) => timeline.event.metadata.slug)
        .concat('prospect')
        .reverse();
    }
    return [];
  }
);
