import React, { useState, useCallback } from 'react';
import {
  styled,
  TextareaAutosize,
  Box,
  Button,
  FormLabel,
} from '@mui/material';

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
      box-sizing: border-box;
      width: 100%;
      font-family: 'Helvetica, Arial', sans-serif;
      padding: 8px 12px;
      border-radius: 4px;
      border: 1px solid ${theme.palette.grey[300]};
      &:focus-visible {
        outline: 2px solid ${theme.palette.primary.main};
      }
    `
);

interface Props {
  onClose: () => void;
  onSubmit: (commentText: string) => void;
}

const ReasonTextArea: React.FC<Props> = ({ onClose, onSubmit }) => {
  const [commentTxt, setCommentTxt] = useState<string>('');

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setCommentTxt(event.target.value);
    },
    []
  );

  const handleSubmit = useCallback(() => {
    if (commentTxt.trim()) {
      onSubmit(commentTxt.trim());
    }
  }, [commentTxt, onSubmit]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <FormLabel>Add Comment</FormLabel>
      <StyledTextarea
        value={commentTxt}
        onChange={handleChange}
        minRows={3}
        placeholder="Add reason..."
      />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        gap={2}
      >
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!commentTxt}
        >
          Save
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default ReasonTextArea;
