import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const LoaderWrapper = styled('div')({
  position: 'fixed',
  top: '50%',
  left: '50%',
  zIndex: 1301,
  width: '100%',
});

const Loader = () => {
  return (
    <LoaderWrapper>
      <CircularProgress color="primary" />
    </LoaderWrapper>
  );
};

export default Loader;
