import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import { ReportAPI } from '../../services/report-api.service';

type ILoading = 'idle' | 'pending' | 'succeeded' | 'failed';

const columnOptions = [
  {
    label: 'Name',
    value: 'name',
    selected: true,
    filterable: true,
    accessor: 'name',
  },
  {
    label: 'Email',
    value: 'email',
    selected: true,
    filterable: false,
    accessor: 'email',
  },
  {
    label: 'Phone',
    value: 'mobile',
    selected: true,
    filterable: false,
    accessor: 'mobile',
  },
  {
    label: 'Job',
    value: 'job',
    selected: true,
    filterable: true,
    accessor: 'job.title',
  },
  {
    label: 'Date',
    value: 'date',
    selected: true,
    filterable: false,
    accessor: 'createdAt',
  },
  {
    label: 'Channel',
    value: 'channel',
    selected: true,
    filterable: true,
    accessor: 'job.channel.name',
  },
  {
    label: 'Pipeline',
    value: 'hiringStatus',
    selected: true,
    filterable: false,
    accessor: 'hiringStatus.state.name',
  },
  {
    label: 'Last Active',
    value: 'lastActive',
    selected: true,
    filterable: false,
    accessor: 'lastActivityTime',
  },
  {
    label: 'Update',
    value: 'update',
    selected: true,
    filterable: false,
    accessor: 'comment',
  },
];

interface IDefaultState {
  columnFilterOptions: {
    loading: ILoading;
    data: any;
  };
  workDone: {
    loading: ILoading;
    data: any;
  };
}

const initialState: IDefaultState = {
  columnFilterOptions: {
    loading: 'idle',
    data: {},
  },
  workDone: {
    loading: 'idle',
    data: [],
  },
};

export const reportOptions = createAsyncThunk(
  'reports/filter-options',
  async (recruiterId: number, { getState }) => {
    const { app } = getState() as RootState;
    //@ts-ignore
    const workspaceId = app.workspace.id;
    const response = await ReportAPI.columnFilterOptions(
      Number(workspaceId),
      recruiterId
    );
    return response.data;
  }
);

export const workDoneReport = createAsyncThunk(
  'reports/work-done-report',
  async (payload: any, { getState }) => {
    const { client } = getState() as RootState;
    const { start = '', end = '', page = 1 } = payload;
    //@ts-ignore
    const activeClientId = client.selectedClient?.id;
    const response = await ReportAPI.getWorkDoneReport(
      Number(activeClientId),
      start,
      end,
      page
    );
    return response.data;
  }
);

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(reportOptions.pending, (state) => {
        state.columnFilterOptions.loading = 'pending';
      })
      .addCase(reportOptions.fulfilled, (state, action) => {
        state.columnFilterOptions.loading = 'succeeded';
        state.columnFilterOptions.data = action.payload;
      })
      .addCase(reportOptions.rejected, (state, action) => {
        state.columnFilterOptions.loading = 'failed';
        state.columnFilterOptions.data = {};
      })
      .addCase(workDoneReport.pending, (state) => {
        state.workDone.loading = 'pending';
      })
      .addCase(workDoneReport.fulfilled, (state, action) => {
        state.workDone.loading = 'succeeded';
        state.workDone.data = action.payload;
      })
      .addCase(workDoneReport.rejected, (state) => {
        state.workDone.loading = 'failed';
        state.workDone.data = [];
      });
  },
});

export const getReportColumns = (state: RootState) => {
  const { loading, data } = state.report.columnFilterOptions;
  if (loading === 'succeeded') {
    return columnOptions.map((column) => {
      let options = null;
      if (column.label == 'Job' && data.jobs?.length) {
        const modifiedArray = data.jobs.map((job: any) => ({
          ...job,
          name: job.title,
        }));
        options = modifiedArray;
      }
      if (column.label == 'Channel') {
        options = data.channels.filter((channel: any) => channel);
      }
      // if (column.label == 'Pipeline') {
      //   const modifiedArray = data.hiringStatus.map((state: any) => ({
      //     ...state,
      //     id: state.stateId,
      //     name: state.state.name,
      //   }));
      //   options = modifiedArray;
      // }
      return {
        ...column,
        options,
      };
    });
  }
  return [];
};

export default reportSlice.reducer;
