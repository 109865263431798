import { useTheme } from '@mui/material/styles';
import { Divider, List, Box } from '@mui/material';

import NotificationItem from './NotificationItem';

const NotificationList = (props: { notification: Array<any> }) => {
  const { notification = [] } = props;
  const theme = useTheme();

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 330,
        py: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
          maxWidth: 300,
        },
        '& .MuiListItemSecondaryAction-root': {
          top: 22,
        },
        '& .MuiDivider-root': {
          my: 0,
        },
        '& .list-container': {
          pl: 7,
        },
      }}
    >
      {notification.map((notification) => (
        <Box key={`notification-${notification.payload.id}`}>
          <NotificationItem notification={notification} />
          <Divider />
        </Box>
      ))}
    </List>
  );
};

export default NotificationList;
