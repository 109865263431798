import { useState, useEffect } from 'react';
import { Snackbar as MUISnackbar, Alert } from '@mui/material';

import { useAppDispatch } from '../../hooks/redux';

import {
  ISnackbar,
  hideSnackbar,
} from '../../redux/features/dashboard/alertSlice';

const Snackbar = (props: ISnackbar) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(hideSnackbar({ open: false }));
    setOpen(false);
  };

  return (
    <MUISnackbar
      open={open}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
    >
      <Alert severity={props.severity} sx={{ width: '100%' }} variant="filled">
        {props.message}
      </Alert>
    </MUISnackbar>
  );
};

export default Snackbar;
