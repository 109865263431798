import { useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  CircularProgressProps,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { useDropzone } from 'react-dropzone';

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const UploadResume = ({
  handleFileUpload,
  filename,
  isSubmitting = false,
  fileProgress = {},
}: any) => {
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      handleFileUpload(acceptedFiles[0]);
    },
    [handleFileUpload]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    disabled: isSubmitting,
  });

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: '2px dashed #eee',
        backgroundColor: '#f5f7fb',
        minHeight: 250,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        my: 2,
      }}
    >
      <input {...getInputProps()} />
      {fileProgress.isSubmitting && (
        <CircularProgressWithLabel value={fileProgress.progress} />
      )}
      {filename ? (
        <Typography variant="body2">
          <FilePresentIcon fontSize="inherit" color="primary" />
          {filename}
        </Typography>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body1">Drop your file here</Typography>
          <Typography variant="body1">OR</Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              my: 1,
            }}
            startIcon={<CloudUploadIcon />}
          >
            Select a file to upload
          </Button>
          <Typography variant="caption">Maximum file size: 3 MB</Typography>
        </Box>
      )}
    </Box>
  );
};

export default UploadResume;
