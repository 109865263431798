import React, { lazy } from 'react';

import SuspenseView from '../components/SuspenseView.react';
import DashboardLayout from '../components/layout/dashboard-layout';
import WorkspaceUsers from '../components/workspace-users';
import Invite from '../components/Invite';
import FallbackUI from '../components/fallback-UI/FallbackPage.react';

const SettingsPage = lazy(() => import('../pages/settings/Settings.page'));
const InviteClientUsersPage = lazy(
  () => import('../pages/settings/InviteClientUsers.page')
);
const IntegrationsPage = lazy(
  () => import('../pages/settings/workspace/integration.page')
);
const WorkspaceFunctionalAreaPage = lazy(
  () => import('../pages/settings/WorkspaceFunctionalArea.page')
);
const Clients = lazy(() => import('../pages/settings/Clients.page'));
const Profile = lazy(() => import('../pages/settings/account/Profile.page'));
const SecurityAndAccess = lazy(
  () => import('../pages/settings/account/SecurityAndAccess')
);

const SettingsRoutes = {
  path: '/',
  element: <DashboardLayout isSettingLayout />,
  children: [
    {
      path: 'settings',
      element: (
        <SuspenseView>
          <SettingsPage />
        </SuspenseView>
      ),
    },
    {
      path: 'settings/members',
      element: <WorkspaceUsers />,
    },
    {
      path: 'settings/integrations',
      element: (
        <SuspenseView>
          <IntegrationsPage />
        </SuspenseView>
      ),
    },
    {
      path: 'settings/invite',
      element: <Invite />,
    },
    {
      path: 'settings/not-found',
      element: <FallbackUI />,
    },
    {
      path: 'settings/invite-client-users',
      element: (
        <SuspenseView>
          <InviteClientUsersPage />
        </SuspenseView>
      ),
    },
    {
      path: 'settings/profile',
      element: (
        <SuspenseView>
          <Profile />
        </SuspenseView>
      ),
    },
    {
      path: 'settings/security-access',
      element: (
        <SuspenseView>
          <SecurityAndAccess />
        </SuspenseView>
      ),
    },
    //   {
    //     path: 'settings/client-users',
    //     element: (
    //       <SuspenseView>
    //         <ClientUsers />
    //       </SuspenseView>
    //     ),
    //   },
    //   {
    //     path: 'settings/email',
    //     element: <EmailComposer />,
    //   },
    {
      path: 'settings/client-list',
      element: (
        <SuspenseView>
          <Clients />
        </SuspenseView>
      ),
    },
    {
      path: 'settings/workspace-functional-area',
      element: (
        <SuspenseView>
          <WorkspaceFunctionalAreaPage />
        </SuspenseView>
      ),
    },
    //   {
    //     path: 'settings/manage-dashboard',
    //     element: (
    //       <SuspenseView>
    //         <ManageDashboard />
    //       </SuspenseView>
    //     ),
    //   },
  ],
};

export default SettingsRoutes;
