import { Typography } from '@mui/material';
import NavGroup from './NavGroup';

interface Props {
  menuItems: Array<any>;
}

const MenuList = ({ menuItems }: Props) => {
  const navItems = menuItems.map((item: any) => {
    if (item.type == 'group') {
      return <NavGroup key={item.id} item={item} />;
    }
    return (
      <Typography key={item.id} variant="h6" color="error" align="center">
        Menu Items Error
      </Typography>
    );
  });
  return <>{navItems}</>;
};

export default MenuList;
