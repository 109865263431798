import * as React from 'react';
import { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface Props {
  label: string;
  onDateChange: (date: Dayjs | null) => void;
  value: Dayjs | null;
}

const SimpleDatePicker: React.FC<Props> = ({
  label = 'Select Date',
  value,
  onDateChange,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker', 'DatePicker']}>
        <DatePicker
          label={label}
          value={value}
          onChange={onDateChange}
          disablePast
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default SimpleDatePicker;
