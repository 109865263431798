import React, { lazy } from 'react';

import BaseLayout from '../components/layout/base-layout';
import SuspenseView from '../components/SuspenseView.react';

const UserInvitationAck = lazy(() => import('../pages/UserInvitationAck.page'));

const ServerAckRoutes = {
  path: '/',
  element: <BaseLayout hideHeader />,
  children: [
    {
      path: '/invitation',
      element: (
        <SuspenseView>
          <UserInvitationAck />
        </SuspenseView>
      ),
    },
  ],
};

export default ServerAckRoutes;
