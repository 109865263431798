import { axiosInstance } from '../../utils/axios.instance';

export default class TalentPoolAPIService {
  static uploadSingleCandidateRequest = (payload: any) =>
    axiosInstance({
      method: 'POST',
      url: '/api/v1/talent-pool/add-candidate',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: payload,
    });

  static uploadResumeFile(data: any) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v1/talent-pool/upload-resume',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data,
    });
  }

  static fetchTalentPoolCandidate(query: any) {
    return axiosInstance({
      method: 'GET',
      url: '/api/v1/talent-pool',
      params: query,
    });
  }
}
