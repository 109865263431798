import _ from 'lodash';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  apiCreateEvaluationTemplate,
  apiDeleteEvaluationTemplate,
  apiGetEvaluationTemplate,
  apiUpdateEvaluationTemplate,
  apiCreateObservation,
  apiGetObservation,
} from '../../services/evaluation.service';
import { fetchCandidateTimeline } from '../candidate/timeline.slice';
import { fetchRecruiterActions } from '../candidate/recruiter-action.slice';
import { candidateById } from '../applications/applicationSlice';

import { RootState } from '../../store';

type loadingType = 'idle' | 'pending' | 'succeeded' | 'failed';

interface InitialState {
  evalTemplate: {
    loading: loadingType;
    data: any;
  };
  evaluations: {
    loading: loadingType;
    data: Array<any>;
  };
}

const initialState: InitialState = {
  evalTemplate: {
    loading: 'idle',
    data: {},
  },
  evaluations: {
    loading: 'idle',
    data: [],
  },
};

export const saveEvalTemplate = createAsyncThunk(
  'create/evalTemplate',
  async (data: any) => {
    const response = await apiCreateEvaluationTemplate(data);
    return response.data;
  }
);

export const updateEvalTemplate = createAsyncThunk(
  'update/evalTemplate',
  async ({ templateId, data }: any) => {
    const response = await apiUpdateEvaluationTemplate(templateId, data);
    return response.data;
  }
);

export const fetchEvalTemplate = createAsyncThunk(
  'fetch/evalTemplate',
  async (jobId: any) => {
    const response = await apiGetEvaluationTemplate(jobId);
    return response.data;
  }
);

export const deleteEvalTemplate = createAsyncThunk(
  'delete/evalTemplate',
  async (templateId: any) => {
    const response = await apiDeleteEvaluationTemplate(templateId);
    return response.data;
  }
);

export const saveFeedback = createAsyncThunk(
  'save/evalFeedback',
  async (data: any, { dispatch }) => {
    const response = await apiCreateObservation(data);
    dispatch(getFeedbacks(data[0].applicationId));
    dispatch(fetchRecruiterActions(data[0].applicationId));
    dispatch(fetchCandidateTimeline(data[0].applicationId));
    dispatch(candidateById(data[0].applicationId));
    return response.data;
  }
);

export const getFeedbacks = createAsyncThunk(
  'get/evalFeedback',
  async (applicationId: any) => {
    const response = await apiGetObservation(applicationId);
    return response.data;
  }
);

export const evaluationTemplate = createSlice({
  name: 'evaluationTemplate',
  initialState,
  reducers: {
    resetEvaluvationSubmit: (state) => {
      state.evaluations.loading = 'idle';
      state.evaluations.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          fetchEvalTemplate.pending,
          updateEvalTemplate.pending,
          saveEvalTemplate.pending
        ),
        (state) => {
          state.evalTemplate.loading = 'pending';
        }
      )
      .addMatcher(
        isAnyOf(
          fetchEvalTemplate.fulfilled,
          updateEvalTemplate.fulfilled,
          saveEvalTemplate.fulfilled
        ),
        (state, action) => {
          state.evalTemplate.loading = 'succeeded';
          state.evalTemplate.data = action.payload;
        }
      )
      .addMatcher(
        isAnyOf(
          fetchEvalTemplate.rejected,
          updateEvalTemplate.rejected,
          saveEvalTemplate.rejected
        ),
        (state) => {
          state.evalTemplate.loading = 'failed';
        }
      )
      .addMatcher(
        isAnyOf(saveFeedback.pending, getFeedbacks.pending),
        (state) => {
          state.evaluations.loading = 'pending';
        }
      )
      .addMatcher(isAnyOf(getFeedbacks.fulfilled), (state, action) => {
        state.evaluations.loading = 'succeeded';
        state.evaluations.data = action.payload;
      })
      .addMatcher(
        isAnyOf(saveFeedback.rejected, getFeedbacks.rejected),
        (state) => {
          state.evaluations.loading = 'failed';
        }
      );
    //   .addMatcher(
    //     isAnyOf(
    //       addHiringWorkflowStage.pending,
    //       modifyHiringWorkflowStage.pending,
    //       destroyHiringWorkflowStage.pending
    //     ),
    //     (state) => {
    //       state.hiringWorkflowResponse.loading = "pending";
    //       state.hiringWorkflowResponse.error = "";
    //     }
    //   )
    //   .addMatcher(
    //     isAnyOf(
    //       addHiringWorkflowStage.fulfilled,
    //       modifyHiringWorkflowStage.fulfilled,
    //       destroyHiringWorkflowStage.fulfilled
    //     ),
    //     (state, action) => {
    //       state.hiringWorkflowResponse.loading = "succeeded";
    //       state.hiringWorkflowResponse.error = "";
    //     }
    //   )
    //   .addMatcher(
    //     isAnyOf(
    //       addHiringWorkflowStage.rejected,
    //       modifyHiringWorkflowStage.rejected,
    //       destroyHiringWorkflowStage.rejected
    //     ),
    //     (state, error: any) => {
    //       console.log("payload", error);
    //       state.hiringWorkflowResponse.loading = "failed";
    //       state.hiringWorkflowResponse.error = error.payload.error;
    //     }
    //   );
  },
});

export const { resetEvaluvationSubmit } = evaluationTemplate.actions;

export default evaluationTemplate.reducer;
