import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { TalentPoolCommentAPI } from '../../services/talent-pool-comment.service';

interface IDefaultState {
  commentList: {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    data: Array<any>;
  };
}

const initialState: IDefaultState = {
  commentList: {
    loading: 'idle',
    data: [],
  },
};

// Thunk API actions

export const fetchComments = createAsyncThunk(
  'talent-pool-comment/fetch',
  async (id: number) => {
    const response = await TalentPoolCommentAPI.fetchComment(id);
    return response.data;
  }
);

export const addComment = createAsyncThunk(
  'talent-pool-comment/addComment',
  async (data: any) => {
    const response = await TalentPoolCommentAPI.addComment(data);
    return response.data;
  }
);

export const updateComment = createAsyncThunk(
  'talent-pool-comment/updateComment',
  async (payload: any) => {
    const response = await TalentPoolCommentAPI.updateComment(
      payload.commentId,
      payload.data
    );
    return response.data;
  }
);

export const likeComment = createAsyncThunk(
  'talent-pool-comment/like',
  async (payload: { commentId: number }) => {
    const response = await TalentPoolCommentAPI.addlikeReactionOnComment(
      payload
    );
    return response.data;
  }
);

const TalentPoolCommentSlice = createSlice({
  name: 'TalentPoolComment',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchComments.pending, (state) => {
        state.commentList.loading = 'pending';
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.commentList.loading = 'succeeded';
        state.commentList.data = action.payload;
      })
      .addCase(fetchComments.rejected, (state) => {
        state.commentList.loading = 'failed';
      })
      .addCase(addComment.pending, (state) => {
        state.commentList.loading = 'pending';
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.commentList.loading = 'succeeded';
        state.commentList.data = action.payload;
      })
      .addCase(addComment.rejected, (state) => {
        state.commentList.loading = 'failed';
      })
      .addCase(updateComment.pending, (state) => {
        state.commentList.loading = 'pending';
      })
      .addCase(updateComment.fulfilled, (state, action) => {
        state.commentList.loading = 'succeeded';
        state.commentList.data = action.payload;
      })
      .addCase(updateComment.rejected, (state) => {
        state.commentList.loading = 'failed';
      })
      .addCase(likeComment.fulfilled, (state, action) => {
        state.commentList.data = action.payload;
      }),
});

export default TalentPoolCommentSlice.reducer;
