import SettingsIcon from '@mui/icons-material/Settings';

const settings = {
  id: 'settings',
  title: 'Settings',
  type: 'group',
  children: [
    {
      id: 'settings',
      title: 'Settings',
      type: 'item',
      url: '/settings',
      icon: SettingsIcon,
      breadcrumbs: false,
    },
  ],
};

export default settings;
