import {
  Table,
  TableBody as MUITableBody,
  Paper,
  Typography,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material';

const TableHeader = ({ headers }: any) => {
  return (
    <TableHead>
      <TableRow>
        {headers.map((header: any, index: any) => (
          <TableCell key={index}>{header.toUpperCase()}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TableBody = ({ body }: any) => {
  return (
    <MUITableBody>
      {body.map((row: any, index: any) => (
        <TableRow key={index}>
          {row.map((col: any, index: any) => (
            <TableCell key={index}>{col}</TableCell>
          ))}
        </TableRow>
      ))}
    </MUITableBody>
  );
};

const CSVPreview = ({ data }: any) => {
  return (
    <Paper sx={{ width: '100%' }}>
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        CSV File Preview
      </Typography>

      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHeader headers={data.shift()} />
          <TableBody body={data} />
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CSVPreview;
