export const stageTypes = [
  {
    name: 'Applied',
    slug: 'APPLIED',
    accepts: 'applied',
    lastDroppedItem: null,
  },
  {
    name: 'Telephonic Interview',
    slug: 'TELEPHONIC',
    accepts: 'telephonic',
    lastDroppedItem: null,
  },
  {
    name: 'Onsite Interview',
    slug: 'INTERVIEW',
    accepts: 'interview',
    lastDroppedItem: null,
  },
  {
    name: 'Evaluvation',
    slug: 'EVALUATION',
    accepts: 'evaluation',
    lastDroppedItem: null,
  },
  { name: 'Offer', slug: 'OFFER', accepts: 'offer', lastDroppedItem: null },
  { name: 'Hired', slug: 'HIRED', accepts: 'hired', lastDroppedItem: null },
];

export const arrApplication = [
  {
    id: 5,
    name: 'Ankur Sharma',
    email: 'abc@mail.com',
    mobile: '976567654',
    resume: null,
    designation: 'Fullstack Developer',
    stage: 'APPLIED',
  },
  {
    id: 2,
    name: 'Akku Sharma',
    email: 'akku@gmail.com',
    mobile: '976567654',
    resume: null,
    designation: 'MERN stack Developer',
    stage: 'APPLIED',
  },
  {
    id: 3,
    name: 'Abhishek Sinha',
    email: 'abhi@gmail.com',
    mobile: '976567654',
    resume: null,
    designation: 'CEO',
    stage: 'APPLIED',
  },
  {
    id: 4,
    name: 'Rajive Sinha',
    email: 'abhi@gmail.com',
    mobile: '976567654',
    resume: null,
    designation: 'CFO',
    stage: 'APPLIED',
  },
];
