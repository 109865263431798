import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';

interface CollapsibleTextProps {
  text: string;
  maxLength?: number;
}

const CollapsibleText: React.FC<CollapsibleTextProps> = ({
  text,
  maxLength = 80,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const shouldTruncate = text.length > maxLength;

  return (
    <Box>
      <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
        {isExpanded || !shouldTruncate
          ? text
          : `${text.substring(0, maxLength)}...`}
        {shouldTruncate && (
          <span
            onClick={toggleExpand}
            style={{
              color: 'blue',
              cursor: 'pointer',
              marginLeft: 5,
              textDecoration: 'underline',
            }}
          >
            {isExpanded ? 'Read Less' : 'Read More'}
          </span>
        )}
      </Typography>
    </Box>
  );
};

export default CollapsibleText;
