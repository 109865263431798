import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  SelectChangeEvent,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { find } from 'lodash';

interface IHiringStateOptions {
  id: number;
  name: string;
  slug: string;
}
interface IHiringStageOptions {
  id: number;
  jobId: number;
  sequenceNo: number;
  stage: {
    id: number;
    name: string;
    slug: string;
    states: IHiringStateOptions[];
  };
}

interface Props {
  hiringWorkflow: IHiringStageOptions[];
  selectedStage: number;
  selectedState: number;
  onSubmit: (stageId: number) => void;
}

const CandidateWorkflowChangeInput: React.FC<Props> = ({
  hiringWorkflow = [],
  selectedStage,
  onSubmit,
}) => {
  const [hiringStage, setHiringStage] = useState<string>(`${selectedStage}`);

  useEffect(() => {
    setHiringStage(`${selectedStage}`);
  }, [selectedStage]);

  const handleStageChange = (e: SelectChangeEvent) => {
    const stageId = e.target.value;
    setHiringStage(stageId);
  };

  const handleSave = useCallback(() => {
    onSubmit(Number(hiringStage));
  }, [onSubmit, hiringStage]);

  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      <FormControl sx={{ minWidth: 150 }} size="small">
        <InputLabel>Stage</InputLabel>
        <Select label="Stage" value={hiringStage} onChange={handleStageChange}>
          {hiringWorkflow.map(({ stage }) => (
            <MenuItem key={stage.id} value={stage.id}>
              {stage.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Tooltip title="Save">
        <IconButton
          color="primary"
          onClick={handleSave}
          disabled={!hiringStage}
        >
          <SaveIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default CandidateWorkflowChangeInput;
