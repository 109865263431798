import * as React from 'react';
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  title?: string;
  children: React.ReactNode;
  handleClose: Function;
  onAccept?: Function;
  acceptBtnText?: string;
  cancelBtnText?: string;
  isActions?: boolean;
  subTitle?: string;
  isLoading?: boolean;
}

const HXLModal = (props: Props) => {
  const {
    title = '',
    children,
    open,
    acceptBtnText = 'Save',
    cancelBtnText = 'Cancel',
    onAccept = null,
    isActions = false,
    subTitle = '',
    isLoading = false,
  } = props;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => props.handleClose()}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '300px',
          },
        },
      }}
    >
      {title && (
        <DialogTitle>
          <Typography>{title}</Typography>
          {subTitle && <Typography variant="caption">{subTitle}</Typography>}
        </DialogTitle>
      )}
      <IconButton
        aria-label="close"
        onClick={() => props.handleClose()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>{children}</DialogContent>
      {isActions && (
        <DialogActions>
          {onAccept && (
            <LoadingButton onClick={() => onAccept()} loading={isLoading}>
              {acceptBtnText}
            </LoadingButton>
          )}
          <Button onClick={() => props.handleClose()}>{cancelBtnText}</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default HXLModal;
