import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  fetchCandidates,
  fetchCandidatesByPostings,
} from '../../services/fetch-candidates';
import { RootState } from '../../store';
import { arrApplication } from '../../../components/hiring-phase/mock-data';
import JobApplicationAPI from '../../services/job-application.service';

export interface ICandidateDetails {
  id?: number;
  name?: string;
  email?: string;
  mobile?: string | number;
  job?: any;
  jobId?: any;
  cv?: string;
  s3Url?: string | null;
  remark?: string;
  createdAt?: string;
  workFlowId?: number;
  state?: any;
  hiringStatus?: any;
  joiningDate?: string | null;
  probationEndDate?: string | null;
}

export interface IApplication {
  id: number;
  name: string;
  designation: string;
  stage: string;
}

type ILoading = 'idle' | 'pending' | 'succeeded' | 'failed';

export interface IApplicationState {
  candidates: {
    total: number;
    list: Array<{}>;
  };
  loading: ILoading;
  error: string;
  candidateDetails: ICandidateDetails;
  candidate: {
    loading: ILoading;
    details: ICandidateDetails;
  };
  snapshot: {
    loading: ILoading;
    data: Array<{
      id: number;
      stageName: string;
      alias: string;
      type: string;
      sequenceNo: number;
      applicationCount: number;
    } | null>;
  };
  hiringCandidates: Array<IApplication>;
}

const initialState: IApplicationState = {
  candidates: {
    list: [],
    total: 0,
  },
  loading: 'idle',
  error: '',
  candidateDetails: {},
  candidate: {
    loading: 'idle',
    details: {},
  },
  hiringCandidates: arrApplication,
  snapshot: {
    loading: 'idle',
    data: [],
  },
};

export const getCandidates = createAsyncThunk(
  'applications/getCandidates',
  async (args: any, { getState }) => {
    const { client: { selectedClient: { id = '' } = {} } = {} } =
      getState() as RootState;
    const response = await fetchCandidates({
      ...args,
      clientId: args.clientId || id,
    });
    return response.data;
  }
);

export const getCandidatesByPosting = createAsyncThunk(
  'applications/getCandidatesByPosting',
  async (payload: { jobId: number; query?: object }) => {
    const response = await fetchCandidatesByPostings(
      payload.jobId,
      payload.query
    );
    return response.data;
  }
);

export const candidateById = createAsyncThunk(
  'applications',
  async (id: number) => {
    const response = await JobApplicationAPI.fetchCandidate(id);
    return response.data;
  }
);

export const jobSnapshot = createAsyncThunk(
  'applications/snapshot',
  async (id: number) => {
    const response = await JobApplicationAPI.fetchJobSnapshot(id);
    return response.data;
  }
);

export const updateApplicant = createAsyncThunk(
  'application/update',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await JobApplicationAPI.updateApplicant(payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || error.message || 'Network Error'
      );
    }
  }
);

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setCandidateDetails: (state, action) => {
      state.candidateDetails = action.payload;
    },
    moveHiringCandidates: (state, action) => {
      const { item = {}, targetStage = '' } = action.payload;
      const update = state.hiringCandidates.map((candidate: any) => {
        if (candidate.id == item.id) {
          return { ...candidate, stage: targetStage };
        }
        return candidate;
      });

      state.hiringCandidates = update;
    },
    resetCandidateList: (state) => {
      state.candidates.list = [];
      state.candidates.total = 0;
      state.loading = 'idle';
    },
    onApplicationChecked: (state, action) => {
      const { payload = '' } = action;
      state.candidates.list = state.candidates.list.map((item: any) =>
        item.id === payload ? { ...item, checked: !item.checked } : item
      );
    },
    changeCandidatePipelineStage: (state, action) => {
      const { candidateId, stageId, stateId } = action.payload;
      state.candidates.list = state.candidates.list.map((candidate: any) => {
        if (candidate.id === candidateId) {
          return {
            ...candidate,
            hiringStatus: {
              stateId,
              stageId,
            },
          };
        }
        return candidate;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(candidateById.pending, (state) => {
        state.candidate.loading = 'pending';
      })
      .addCase(candidateById.fulfilled, (state, action) => {
        state.candidate.loading = 'succeeded';
        state.candidate.details = action.payload;
      })
      .addCase(candidateById.rejected, (state) => {
        state.candidate.loading = 'failed';
      })
      .addCase(updateApplicant.fulfilled, (state, action) => {
        state.candidate.loading = 'succeeded';
        state.candidate.details = action.payload.candidate;
      })
      .addCase(jobSnapshot.pending, (state) => {
        state.snapshot.loading = 'pending';
      })
      .addCase(jobSnapshot.fulfilled, (state, action) => {
        state.snapshot.loading = 'succeeded';
        state.snapshot.data = action.payload;
      })
      .addCase(jobSnapshot.rejected, (state) => {
        state.snapshot.loading = 'failed';
      })
      .addMatcher(
        isAnyOf(getCandidatesByPosting.pending, getCandidates.pending),
        (state) => {
          return {
            ...state,
            loading: 'pending',
          };
        }
      )
      .addMatcher(
        isAnyOf(getCandidatesByPosting.fulfilled, getCandidates.fulfilled),
        (state, action) => {
          return {
            ...state,
            candidates: {
              list: [...state.candidates.list, ...action.payload.list],
              total: action.payload.total,
              candidateIds: action.payload.candidateIds,
            },
            loading: 'succeeded',
          };
        }
      )
      .addMatcher(
        isAnyOf(getCandidatesByPosting.rejected, getCandidates.rejected),
        (state) => {
          return {
            ...state,
            loading: 'failed',
          };
        }
      );
  },
});

export const {
  setCandidateDetails,
  resetCandidateList,
  moveHiringCandidates,
  onApplicationChecked,
  changeCandidatePipelineStage,
} = applicationSlice.actions;

export default applicationSlice.reducer;
