import { useState, useEffect } from 'react';

import {
  Typography,
  Table,
  TableCell,
  tableCellClasses,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Stack,
  Button,
  Avatar,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled, Theme } from '@mui/material/styles';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import { useConfirm } from 'material-ui-confirm';

import { useAppSelector } from '../../hooks/redux';
import { stringAvatar } from '../../utils/helper-functions';
import WorkspaceAPI from '../../redux/services/workspace.service';

const StyledTableCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const defaultListState = {
  isLoading: true,
  list: [],
};

const InvitedUser = () => {
  const confirm = useConfirm();
  const [invitedUsersState, setInvitedUserState] = useState(defaultListState);
  const { app, client: { selectedClient = {} } = {} }: any = useAppSelector(
    (state) => state
  );

  const fetchUsers = (workspaceId: any, clientId: any) => {
    WorkspaceAPI.getChannelUsers(workspaceId, clientId)
      .then(({ data }) => {
        setInvitedUserState({
          list: data,
          isLoading: false,
        });
      })
      .catch((err) => {
        setInvitedUserState((prev) => {
          return {
            ...prev,
            isLoading: false,
          };
        });
      });
  };

  useEffect(() => {
    if (app.workspace.id && selectedClient.id) {
      setInvitedUserState({
        list: [],
        isLoading: true,
      });
      fetchUsers(app.workspace.id, selectedClient.id);
    }
  }, [app.workspace.id, selectedClient.id]);

  const handleDelete = (user: any) => {
    confirm({
      description: `Are you sure you want to remove ${user.name}? This action cannot be undon`,
    })
      .then(() => {
        WorkspaceAPI.deleteChannelUser(
          app.workspace.id,
          selectedClient.id,
          user.id
        )
          .then((res) => {
            fetchUsers(app.workspace.id, selectedClient.id);
          })
          .catch((err) => {
            //fetchUsers(app.workspace.id, selectedClient.id);
          });
      })
      .catch((err) => {});
  };

  const { list = [] } = invitedUsersState;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell align="left">Permissions</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
            <StyledTableCell align="center">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((user: any) => {
            return (
              <StyledTableRow key={`candidate-${user.id}`} hover>
                <StyledTableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <LocalPostOfficeIcon
                      color="primary"
                      fontSize="inherit"
                      sx={{ mr: 1 }}
                    />
                    {user.email}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap={1}
                  >
                    {user.permissions.map((permission: any, i: number) => (
                      <Tooltip key={`permission-${i}`} title={permission.label}>
                        <Avatar
                          {...stringAvatar(permission.label)}
                          sx={{
                            width: 24,
                            height: 24,
                            fontSize: 12,
                            background: '#2065D1',
                          }}
                        />
                      </Tooltip>
                    ))}
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography
                    variant="body2"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {user.status}
                  </Typography>
                </StyledTableCell>

                <StyledTableCell align="center">
                  <Button
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDelete(user)}
                  >
                    Remove
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvitedUser;
