import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import NotificationAPI from '../../services/notification.service';

interface IState {
  notification: any;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string;
}

const initialState: IState = {
  notification: {},
  loading: 'idle',
  error: '',
};

export const fetchNotification = createAsyncThunk(
  'notification/fetch',
  async (page) => {
    const response = await NotificationAPI.getNotification(Number(page));
    return response.data;
  }
);

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotification.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchNotification.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.notification = action.payload;
      })
      .addCase(fetchNotification.rejected, (state) => {
        state.loading = 'failed';
      });
  },
});

export default notificationSlice.reducer;
