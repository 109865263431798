import Cookies from 'universal-cookie';

import { axiosInstance } from '../../utils/axios.instance';
import { SendEmail } from '../../types/job';
const cookies = new Cookies();

export const fetchPrimaryFunctionalArea = (workpaceId: number) => {
  return axiosInstance({
    method: 'GET',
    url: `/api/v1/job/functional-area/${workpaceId}`,
  });
};

export const fetchSecondaryFunctionalArea = (id: number) => {
  return axiosInstance({
    method: 'GET',
    url: `/api/v1/job/secondary-functional-area/${id}`,
  });
};

export const fetchCitiesList = () => {
  return axiosInstance({
    method: 'GET',
    url: '/api/v1/job/cities',
  });
};

export const fetchStackholders = (
  workspaceId: number | null,
  clientId: number | null
) => {
  return axiosInstance({
    method: 'GET',
    url: `/api/v1/workspace/stakeholder/${workspaceId}/${clientId}`,
  });
};

export const createOpening = (job: any) => {
  job['token'] = cookies.get('authToken');

  return axiosInstance({
    method: 'POST',
    url: '/api/v1/job/create',
    data: job,
    headers: {
      'hxl-workspace': job.workspaceId,
    },
  });
};

export const fetchJobById = (id: number) => {
  axiosInstance.defaults.headers['content-type'] = 'application/json';
  axiosInstance.defaults.headers['job'] = id;
  return axiosInstance({
    method: 'GET',
    url: `/api/v2/job/${id}/summary`,
  });
};

export const updateOpening = (id: string, data: any, workspaceId: number) => {
  axiosInstance.defaults.headers['content-type'] = 'application/json';
  axiosInstance.defaults.headers['job'] = id;
  axiosInstance.defaults.headers['hxl-workspace'] = workspaceId;
  return axiosInstance({
    method: 'PUT',
    url: '/api/v1/job/edit',
    data,
  });
};

export const sendJobDescriptionEmail = (data: SendEmail) => {
  return axiosInstance({
    method: 'POST',
    url: '/api/v1/job-application/send-job-email',
    data,
  });
};
