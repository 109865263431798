import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { reqShareCandidateViaEmail } from '../../services/share-candidate-via-email';

interface InitialState {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: InitialState = {
  loading: 'idle',
};

export const shareCandidateViaEmail = createAsyncThunk(
  'applications/shareViaEmail',
  async (data) => {
    const response = await reqShareCandidateViaEmail(data);
    return response.data;
  }
);

export const applicantEmailSlice = createSlice({
  name: 'applicantEmail',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.loading = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(shareCandidateViaEmail.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(shareCandidateViaEmail.fulfilled, (state) => {
        state.loading = 'succeeded';
      })
      .addCase(shareCandidateViaEmail.rejected, (state) => {
        state.loading = 'failed';
      });
  },
});

export const { resetStatus } = applicantEmailSlice.actions;

export default applicantEmailSlice.reducer;
