import dayjs, { Dayjs } from 'dayjs';
import { makeRequest } from '../../utils/axios.instance';
import { axiosInstance } from '../../utils/axios.instance';

export default class WorkspaceAPI {
  static async getChannelUsers(workspaceId: number, clientId: number) {
    return axiosInstance({
      method: 'GET',
      url: `/api/v1/workspace/stakeholder/${workspaceId}/${clientId}`,
    });
  }

  static async getWorkspaceUsers(workspaceId: number) {
    return await makeRequest(
      'GET',
      `/api/v1/workspace/stakeholder/${workspaceId}`
    );
  }

  static async deleteChannelUser(
    workspaceId: number,
    clientId: number,
    userId: number
  ) {
    return axiosInstance({
      method: 'DELETE',
      url: `/api/v1/workspace/stakeholder/${workspaceId}/${clientId}/${userId}`,
    });
  }

  static async getWorkspaceInvitation(workspaceId: number) {
    return axiosInstance({
      method: 'GET',
      url: `/api/v1/user-invitation/workspace-invitation-list/${workspaceId}`,
    });
  }

  static async getWorkspaceFunctionalArea(workspaceId: number) {
    return axiosInstance({
      method: 'GET',
      url: `/api/v1/job/functional-area-with-secondary-area/${workspaceId}`,
    });
  }

  static async addWorkspaceFunctionalArea(data: {
    name: string;
    workspaceId: number;
  }) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v1/job/functional-area',
      data,
    });
  }

  static async addWorkspaceSecondaryFunctionalArea(data: {
    name: string;
    functionalAreaId: number;
  }) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v1/job/secondary-functional-area',
      data,
    });
  }

  static async deleteWorkspaceFunctionalArea(data: { id: number }) {
    return axiosInstance({
      method: 'DELETE',
      url: '/api/v1/job/functional-area',
      data,
    });
  }

  static async deleteWorkspaceSecondaryFunctionalArea(data: { id: number }) {
    return axiosInstance({
      method: 'DELETE',
      url: '/api/v1/job/secondary-functional-area',
      data,
    });
  }

  static async requestWorkspaceAccess(data: { email: string }) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v1/workspace/access-request',
      data,
    });
  }

  static async fetchWorkspaceRoles() {
    return axiosInstance({
      method: 'GET',
      url: '/api/v1/job/roles',
    });
  }

  static async updateAccess(
    userId: number,
    channelId: number,
    role: number,
    scope: Array<string>
  ) {
    return axiosInstance({
      method: 'PUT',
      url: '/api/v1/channel/access-control',
      data: {
        userId,
        channelId,
        role,
        scope,
      },
    });
  }

  static async fetchRecruiterApplication(
    recruiterId: number,
    page: number = 1,
    time: string = '1d'
  ) {
    return axiosInstance({
      method: 'GET',
      url: `/api/v1/dashboard/recruiter-application?page=${page}&&recruiterId=${recruiterId}&&time=${time}`,
    });
  }

  static async fetchRecruiterApplicationByQuery(
    query: any,
    timeRange: undefined | [Dayjs, Dayjs],
    recruiter: number,
    page: number = 1
  ) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v2/dashboard/recruiter-application',
      data: {
        query,
        timeRange:
          timeRange && timeRange[0] && timeRange[1]
            ? [
                dayjs(timeRange[0]).format('DD-MM-YYYY'),
                dayjs(timeRange[1]).format('DD-MM-YYYY'),
              ]
            : null,
        recruiter,
        page,
      },
    });
  }

  static async fetchRecruiterApplicationAggregation(
    query: any,
    timeRange: undefined | [Dayjs, Dayjs],
    recruiter: number,
    page: number = 1
  ) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v2/dashboard/recruiter-application-aggregation',
      data: {
        query,
        timeRange:
          timeRange && timeRange[0] && timeRange[1]
            ? [
                dayjs(timeRange[0]).format('DD-MM-YYYY'),
                dayjs(timeRange[1]).format('DD-MM-YYYY'),
              ]
            : null,
        recruiter,
        page,
      },
    });
  }

  static async fetchLeaderBoard() {
    return axiosInstance({
      method: 'GET',
      url: '/api/v1/dashboard/leaderboard',
    });
  }
}
