import _ from 'lodash';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  fetchHiringWorkflow,
  createHiringWorkflowStage,
  updateHiringWorkflowStage,
  deleteHiringWorkflowStage,
  fetchHiringWorkflowCandidates,
} from '../../services/hiring-workflow.service';
import { RootState } from '../../store';

type loadingType = 'idle' | 'pending' | 'succeeded' | 'failed';

interface InitialState {
  hiringWorkflow: {
    loading: loadingType;
    data: any;
  };
  hiringWorkflowResponse: {
    loading: loadingType;
    data: any;
    error: any;
  };
  candidates: {
    loading: loadingType;
    data: any;
  };
}

const initialState: InitialState = {
  hiringWorkflow: {
    loading: 'idle',
    data: {},
  },
  hiringWorkflowResponse: {
    loading: 'idle',
    data: '',
    error: '',
  },
  candidates: {
    loading: 'idle',
    data: [],
  },
};

export const getHiringWorkflow = createAsyncThunk(
  'fetch/hiringWorkflow',
  async (jobId: number) => {
    const response = await fetchHiringWorkflow(jobId);
    return response.data;
  }
);

export const getHiringWorkflowCandidate = createAsyncThunk(
  'fetch/hiringWorkflowCandidate',
  async (jobId: number) => {
    const response = await fetchHiringWorkflowCandidates(jobId);
    return response.data;
  }
);

export const addHiringWorkflowStage = createAsyncThunk(
  'create/hiringWorkflow',
  async (data: any, { getState }) => {
    const { app }: any = getState() as RootState;
    const response = await createHiringWorkflowStage({
      ...data,
      states: data.states.map((state: string) => ({ name: state })),
      workspaceId: app.workspace?.id,
    });
    return response.data;
  }
);

export const modifyHiringWorkflowStage = createAsyncThunk(
  'update/hiringWorkflow',
  async (data: any) => {
    const response = await updateHiringWorkflowStage(data);
    return response.data;
  }
);

export const destroyHiringWorkflowStage = createAsyncThunk(
  'delete/hiringWorkflow',
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await deleteHiringWorkflowStage({ id });
      return response.data;
    } catch (error) {
      //@ts-ignore
      if (!error.response) {
        throw error;
      }
      //@ts-ignore
      return rejectWithValue(error.response.data);
    }
  }
);

export const hiringWorkflowSlice = createSlice({
  name: 'hiringWorkflow',
  initialState,
  reducers: {
    resetHiringStage: (state) => {
      state.hiringWorkflowResponse.loading = 'idle';
    },
    updateHiringStages: (state, action) => {
      const { updatedStage } = action.payload;
      state.hiringWorkflow.data = updatedStage;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHiringWorkflow.pending, (state) => {
        state.hiringWorkflow.loading = 'pending';
      })
      .addCase(getHiringWorkflow.fulfilled, (state, action) => {
        state.hiringWorkflow.loading = 'succeeded';
        state.hiringWorkflow.data = action.payload;
      })
      .addCase(getHiringWorkflow.rejected, (state) => {
        state.hiringWorkflow.loading = 'failed';
      })
      .addCase(getHiringWorkflowCandidate.pending, (state) => {
        state.candidates.loading = 'pending';
      })
      .addCase(getHiringWorkflowCandidate.fulfilled, (state, action) => {
        state.candidates.loading = 'succeeded';
        state.candidates.data = action.payload;
      })
      .addCase(getHiringWorkflowCandidate.rejected, (state) => {
        state.candidates.loading = 'failed';
      })
      .addMatcher(
        isAnyOf(
          addHiringWorkflowStage.pending,
          modifyHiringWorkflowStage.pending,
          destroyHiringWorkflowStage.pending
        ),
        (state) => {
          state.hiringWorkflowResponse.loading = 'pending';
          state.hiringWorkflowResponse.error = '';
        }
      )
      .addMatcher(
        isAnyOf(
          addHiringWorkflowStage.fulfilled,
          modifyHiringWorkflowStage.fulfilled,
          destroyHiringWorkflowStage.fulfilled
        ),
        (state, action) => {
          state.hiringWorkflowResponse.loading = 'succeeded';
          state.hiringWorkflowResponse.error = '';
        }
      )
      .addMatcher(
        isAnyOf(
          addHiringWorkflowStage.rejected,
          modifyHiringWorkflowStage.rejected,
          destroyHiringWorkflowStage.rejected
        ),
        (state, error: any) => {
          state.hiringWorkflowResponse.loading = 'failed';
          state.hiringWorkflowResponse.error = error.payload.error;
        }
      );
  },
});

export const { resetHiringStage, updateHiringStages } =
  hiringWorkflowSlice.actions;

export const getJobHiringStates = (state: RootState) => {
  const { loading, data } = state.hiringWorkflow.hiringWorkflow;
  if (loading === 'succeeded') {
    return data.flatMap(({ stage }: any) =>
      stage.states.map((state: any) => ({
        label: `${state.name}-${stage.name}`,
        value: state.id,
      }))
    );
  }
  return [];
};

export default hiringWorkflowSlice.reducer;
