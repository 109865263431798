import { createSlice } from '@reduxjs/toolkit';

export interface IUploadApplicant {
  selectedJob: {
    title?: string;
    id?: string;
  };
  uploadType: 'single' | 'multiple' | 'all';
  candidateUploadCvs: Array<any>;
  progress: any;
  error: any;
}

const initialState: IUploadApplicant = {
  selectedJob: {},
  uploadType: 'single',
  candidateUploadCvs: [],
  progress: {},
  error: {},
};

export const uploadApplicantSlice = createSlice({
  name: 'uploadApplicant',
  initialState,
  reducers: {
    setSelectedJob: (state, action) => {
      state.selectedJob = action.payload;
    },
    setUploadType: (state, action) => {
      state.uploadType = action.payload;
    },
    setUploadFiles: (state, action) => {
      state.candidateUploadCvs = [
        ...state.candidateUploadCvs,
        ...action.payload,
      ];
    },
    resetUploadFiles: (state) => {
      state.candidateUploadCvs = [];
    },
    setUploadProgress: (state, action) => {
      const { file, progress } = action.payload;
      const fileObj = state.candidateUploadCvs.find((cv) => cv.name == file);
      fileObj['status'] = progress === 'error' ? 'error' : `${progress}`;
    },
    setUploadError: (state, action) => {
      const { file, error } = action.payload;
      state.error[file.name] = error;
    },
  },
});

export const {
  setSelectedJob,
  setUploadType,
  setUploadFiles,
  resetUploadFiles,
  setUploadProgress,
  setUploadError,
} = uploadApplicantSlice.actions;

export default uploadApplicantSlice.reducer;
