export default class GithubAppLink {
  private static repoOwner: string = 'a4abs';
  private static repo: string = 'hirexl-recruiters-app';

  static async getAppUpdateLink(os: 'windows' | 'macos') {
    const response = await fetch(
      `https://api.github.com/repos/${this.repoOwner}/${this.repo}/releases/latest`
    );
    const data = await response.json();
    const assetPatterns = {
      windows: /.exe/i,
      macos: /.dmg/i,
    };

    const osPattern = assetPatterns[os];
    if (!osPattern) {
      console.error('Unsupported operating system');
      return;
    }

    // Find the asset matching the OS pattern
    const { browser_download_url = '' } = data.assets.find((asset: any) =>
      osPattern.test(asset.name)
    );

    if (!browser_download_url) {
      return;
    }

    //@ts-ignore
    const isElectron = window && window.process && window.process.type;
    if (isElectron) {
      const { ipcRenderer } = window.require('electron');
      ipcRenderer.invoke('download-file', browser_download_url);
    } else {
      window.open(browser_download_url);
    }
  }
}
