import { makeRequest } from '../../utils/axios.instance';

export default class WorkspaceChannelAPI {
  static async getStakeholders(workspaceId: number, channelId: number) {
    return makeRequest(
      'GET',
      `/api/v1/workspace/stakeholder/${workspaceId}/${channelId}`
    );
  }
}
