import { Theme } from '@mui/material/styles';
export default function Chip(theme: Theme) {
  return {
    MuiChip: {
      styleOverrides: {
        label: {
          textTransform: 'capitalize',
        },
      },
    },
  };
}
