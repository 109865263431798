import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';

import { useAppDispatch } from '../hooks/redux';
import { logout } from '../redux/features/onboarding/loginSlice';

export default function () {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logout());
    navigate('/');
  }, []);

  return (
    <Box>
      <CircularProgress size={28} />
      <Typography variant="body1">Logging out...</Typography>
      <Typography variant="caption">
        Thanks for using HireXL, don't forget to give feedback about it!
      </Typography>
    </Box>
  );
}
