import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  Stack,
  IconButton,
  TextField,
  FormHelperText,
} from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import MessageIcon from '@mui/icons-material/Message';
import dayjs from 'dayjs';
import { isNumber } from 'lodash';

const REGEX_EMAIL = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const getColor = (status: 'Active' | 'Upcoming' | 'Done') => {
  let color = 'success';
  switch (status) {
    case 'Active':
      color = 'primary';
      break;
    case 'Upcoming':
      color = 'default';
      break;
  }
  return color;
};

const Header = (props: any) => {
  const {
    name = '',
    email = '',
    mobile = '',
    job = '',
    remark = '',
    createdAt = 0,
    stageProgress = [],
    onUpdate,
  } = props;

  const [editableValue, setEditableValue] = useState({
    name: {
      editing: false,
      value: '',
    },
    email: {
      editing: false,
      value: '',
    },
    mobile: {
      editing: false,
      value: '',
    },
  });
  const [error, setError] = useState('');

  useEffect(() => {
    setEditableValue({
      name: {
        editing: false,
        value: name,
      },
      email: {
        editing: false,
        value: email,
      },
      mobile: {
        editing: false,
        value: mobile,
      },
    });
  }, [name, email, mobile]);

  const enableEditing = (key: string) => {
    setEditableValue((prev: any) => ({
      ...prev,
      [key]: {
        ...prev[key],
        editing: !prev[key].editing,
      },
    }));
    setError('');
  };

  const handleChange = (key: string, e: ChangeEvent<HTMLInputElement>) => {
    if (key == 'mobile') {
      if (e.target.value.length > 10) {
        return;
      }
      if (!isNumber(Number(e.target.value))) {
        return;
      }
    }
    setError('');
    setEditableValue((prev: any) => ({
      ...prev,
      [key]: {
        ...prev[key],
        value: e.target.value,
        error: '',
      },
    }));
  };

  const handleSave = () => {
    setEditableValue((prev: any) => ({
      ...prev,
      name: {
        ...prev.name,
        editing: false,
      },
      mobile: {
        ...prev.mobile,
        editing: false,
      },
      email: {
        ...prev.email,
        editing: false,
      },
    }));
    if (!REGEX_EMAIL.test(editableValue.email.value)) {
      setError('Email is required!');
    } else {
      onUpdate({
        name: editableValue.name.value,
        mobile: editableValue.mobile.value,
        email: editableValue.email.value,
      }).catch((error: any) => {
        setError(error);
      });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Candidate Details
        </Typography>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignContent="center"
          gap={1}
          py={1}
          flexWrap="wrap"
        >
          {stageProgress.length > 0 &&
            stageProgress.map((stage: any) => {
              const color: any = getColor(stage.status).toString();
              return (
                <Chip
                  key={`chip-progress-${stage.diaplayName}`}
                  label={stage.diaplayName}
                  disabled={stage.status == 'Upcoming'}
                  color={color}
                  size="small"
                  variant="filled"
                />
              );
            })}
        </Stack>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box>
            {editableValue.name.editing ? (
              <TextField
                autoFocus
                label="Name"
                size="small"
                onBlur={handleSave}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange('name', e)
                }
                onKeyDown={handleKeyDown}
                value={editableValue.name.value}
              />
            ) : (
              <Box flexDirection="row" display="flex" alignItems="center">
                <PersonIcon fontSize="inherit" color="primary" />
                <Typography variant="body2" ml={1}>
                  {editableValue.name.value}
                </Typography>
                <IconButton size="small" onClick={() => enableEditing('name')}>
                  <EditIcon fontSize="inherit" color="primary" />
                </IconButton>
              </Box>
            )}

            {editableValue.mobile.editing ? (
              <TextField
                autoFocus
                label="Mobile"
                size="small"
                onBlur={handleSave}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange('mobile', e)
                }
                onKeyDown={handleKeyDown}
                value={editableValue.mobile.value}
              />
            ) : (
              <Box flexDirection="row" display="flex" alignItems="center">
                <PhoneIcon fontSize="inherit" color="primary" />
                <Typography variant="body2" ml={1}>
                  {editableValue.mobile.value}
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => enableEditing('mobile')}
                >
                  <EditIcon fontSize="inherit" color="primary" />
                </IconButton>
              </Box>
            )}
            {editableValue.email.editing ? (
              <TextField
                autoFocus
                label="Email"
                size="small"
                onBlur={handleSave}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange('email', e)
                }
                onKeyDown={handleKeyDown}
                value={editableValue.email.value}
              />
            ) : (
              <Box flexDirection="row" display="flex" alignItems="center">
                <EmailIcon fontSize="inherit" color="primary" />
                <Typography variant="body2" ml={1}>
                  {editableValue.email.value}
                </Typography>
                <IconButton size="small" onClick={() => enableEditing('email')}>
                  <EditIcon fontSize="inherit" color="primary" />
                </IconButton>
              </Box>
            )}
            <FormHelperText error>{error}</FormHelperText>
          </Box>
          <Box>
            {job && (
              <Box flexDirection="row" display="flex" alignItems="center">
                <WorkIcon fontSize="inherit" color="primary" />
                <Typography variant="body2" ml={1}>
                  {`Applied to: ${job?.title?.toUpperCase()} position`}
                </Typography>
              </Box>
            )}
            {createdAt && (
              <Box flexDirection="row" display="flex" alignItems="center">
                <AccessTimeIcon fontSize="inherit" color="primary" />
                <Typography variant="body2" ml={1}>
                  {dayjs(createdAt).format('MMM DD, YYYY hh:mm:ss A')}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        {remark && (
          <Box mt={2}>
            <Box flexDirection="row" display="flex" alignItems="center">
              <MessageIcon fontSize="inherit" color="primary" />
              <Typography variant="body2" ml={1}>
                Remark
              </Typography>
            </Box>
            <Typography variant="body2">{remark}</Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default Header;
