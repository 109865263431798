import React, { useCallback, useEffect, useState, memo, useRef } from 'react';
import {
  Stack,
  Avatar,
  Tooltip,
  FormControl,
  Button,
  TextareaAutosize,
  styled,
  Popper,
  Paper,
  MenuItem,
} from '@mui/material';

import EmojiInput from './EmojiInput.react';
import { stringAvatar, stringToColor } from '../../../utils/helper-functions';

const StyledTextareaAutosize = styled(TextareaAutosize)({
  width: '100%',
  fontFamily: 'inherit',
  padding: 5,
});

interface Props {
  mode: 'EDIT' | 'REPLY' | 'COMMENT';
  user: {
    name: string;
    id: number;
    profilePicUrl?: string;
  };
  commentText?: string;
  onCancel?: Function;
  onSubmit: Function;
  commentParentId?: number | null;
  commentId?: number | null;
  isSubmitting?: boolean;
  mentionUsers?: Array<{ id: number; name: string; email: string }>;
}

const RegularInput = memo((props: Props) => {
  const {
    mode = 'COMMENT',
    user,
    commentText = '',
    onSubmit,
    commentParentId = null,
    commentId = null,
    onCancel,
    isSubmitting = false,
    mentionUsers = [],
  } = props;
  const textAreaRef = useRef(null);
  const [inputText, setInputText] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<any>(null);

  useEffect(() => {
    setInputText(commentText);
  }, [commentText]);

  useEffect(() => {
    if (!isSubmitting && mode == 'COMMENT') {
      setInputText('');
    }
  }, [isSubmitting, mode]);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = e.target.value;
    setInputText(value);
  };

  const handleMentionSelect = (mention: string) => {
    const textArea: any = textAreaRef.current;

    const { selectionStart, selectionEnd } = textArea;

    const newText = `${inputText.substring(
      0,
      selectionStart - 1
    )} ${mention} ${inputText.substring(selectionEnd, inputText.length)}`;

    setInputText(newText);
    // Hide mention suggestions
    setAnchorEl(null);
  };

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSubmit(mode, inputText, commentId, commentParentId);
    },
    [commentParentId, inputText]
  );

  const handleSetEmoji = (emoji: any) => {
    setInputText((prev) => `${prev} ${emoji}`);
  };

  const handleKeyUp = (e: any) => {
    const { selectionStart } = e.target;
    // Get the text from the beginning to the cursor position
    const cursorPos = selectionStart;
    const lastChar = cursorPos > 0 ? inputText.charAt(cursorPos - 1) : '';
    if (lastChar == '@') {
      setAnchorEl(e.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  return (
    <Stack
      component="form"
      direction="row"
      alignItems="center"
      gap={1}
      width="100%"
      onSubmit={handleSubmit}
    >
      <Tooltip title={user.name}>
        <Avatar
          {...stringAvatar(user.name)}
          sx={{
            width: 30,
            height: 30,
            fontSize: '12px',
            bgcolor: stringToColor(user.name),
          }}
        />
      </Tooltip>
      <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row' }}>
        <StyledTextareaAutosize
          ref={textAreaRef}
          minRows={2}
          value={inputText}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
        />
        <EmojiInput onSetEmoji={handleSetEmoji} />
      </FormControl>
      {mentionUsers?.length > 0 && (
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          sx={{ zIndex: 1500 }}
        >
          <Paper>
            {mentionUsers.map((user) => (
              <MenuItem
                key={`mention-user-list-item-${user.id}`}
                onClick={() => handleMentionSelect(`@${user.email}`)}
              >
                {user.name}
              </MenuItem>
            ))}
          </Paper>
        </Popper>
      )}
      {mode != 'COMMENT' && (
        // @ts-ignore
        <Button variant="outlined" size="small" onClick={onCancel}>
          Cancel
        </Button>
      )}
      <Button
        variant="outlined"
        size="small"
        type="submit"
        disabled={isSubmitting}
      >
        Save
      </Button>
    </Stack>
  );
});

export default RegularInput;
