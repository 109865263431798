import { axiosInstance } from '../../utils/axios.instance';

export const fetchJobs = (
  page = 1,
  workspaceId?: number | null,
  clientId?: number | null,
  status?: string | null,
  selfPosted?: boolean
) => {
  return axiosInstance({
    method: 'GET',
    url: `/api/v2/job/${workspaceId}/${clientId}`,
    params: {
      page,
      status,
      selfPosted,
    },
  });
};
