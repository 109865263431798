import React, { useState } from 'react';
import {
  Stack,
  Autocomplete,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

interface Option {
  label: string;
  value: string | number;
}

interface Props {
  options: Option[];
  onSubmit: (selectedValues: Option[], moveToClientReview: boolean) => void;
  onCancel: () => void;
}

const SelectStakeholderInput = ({ options, onSubmit, onCancel }: Props) => {
  const [selectedValues, setSelectedValues] = useState<Option[]>([]);
  const [isSendToClient, setIsSendToClient] = useState<boolean>(false);

  const handleInputChange = (
    _: React.ChangeEvent<unknown>,
    value: Option[]
  ) => {
    setSelectedValues(value);
  };

  const handleSubmit = () => {
    onSubmit(selectedValues, isSendToClient);
  };

  const handleOnCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSendToClient(event.target.checked);
  };

  return (
    <Stack direction="column" p={2}>
      <Autocomplete
        multiple
        filterSelectedOptions
        sx={{
          minWidth: 400,
        }}
        value={selectedValues}
        options={options}
        size="small"
        onChange={handleInputChange}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Reviewer"
            placeholder="Select Reviewer"
          />
        )}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isSendToClient}
            onChange={handleOnCheck}
            size="small"
          />
        }
        label={
          <Typography variant="caption">
            Move the candidate to Client Review
          </Typography>
        }
      />
      <Stack direction="row" justifyContent="flex-start" gap={2}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!selectedValues.length}
          sx={{ px: 1 }}
          startIcon={<ShareOutlinedIcon fontSize="inherit" />}
        >
          Share
        </Button>
        <Button variant="outlined" onClick={onCancel} sx={{ px: 1 }}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

export default SelectStakeholderInput;
