import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/redux';
import Header from './Header';
import Footer from '../footer';
import Snackbar from '../../Snackbar/Snackbar';

const BaseLayout = ({ hideHeader = false }: { hideHeader?: boolean }) => {
  const snackbarAlert = useAppSelector((state) => state.alert.snackbarAlert);
  return (
    <>
      {!hideHeader && <Header />}
      <Snackbar {...snackbarAlert} />
      <Box
        justifyContent="center"
        display="flex"
        alignItems="center"
        flexDirection="column"
        minHeight="50vh"
      >
        <Outlet />
      </Box>
      {!hideHeader && <Footer />}
    </>
  );
};

export default BaseLayout;
