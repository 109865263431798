import queryString from 'query-string';

import { axiosInstance } from '../../utils/axios.instance';

export default class JobAPI {
  private static async makeRequest<T>(
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    url: string,
    data?: any
  ): Promise<T> {
    try {
      const response = await axiosInstance({
        method,
        url,
        data,
      });
      return response.data;
    } catch (error) {
      console.log('JobAPI', error);
      throw new Error('An error occurred');
    }
  }
  static async fetchPrimaryFunctionalArea(workpaceId: number) {
    return this.makeRequest('GET', `/api/v1/job/functional-area/${workpaceId}`);
  }

  static async fetchCitiesList() {
    return this.makeRequest('GET', '/api/v1/job/cities');
  }

  static async stakeholder(jobId: number) {
    return this.makeRequest('GET', `/api/v2/job/${jobId}/stakeholder`);
  }

  static async hiringWorkflow(jobId: number) {
    return this.makeRequest('GET', `/api/v2/hiring-workflow?job=${jobId}`);
  }

  static async fetchTodayAssignedJob() {
    return this.makeRequest(
      'GET',
      '/api/v1/recruiter-activity-tracker/day-assign-job'
    );
  }

  static async fetchRecruiterTodayAssignedJob(recruiterId: number) {
    return this.makeRequest(
      'GET',
      `/api/v1/recruiter-activity-tracker/admin/day-assign-job/${recruiterId}`
    );
  }

  static async assignJobForDay(jobId: number) {
    return this.makeRequest(
      'POST',
      '/api/v1/recruiter-activity-tracker/assign-job',
      { jobId }
    );
  }

  static async unassignJobForDay(jobId: number) {
    return this.makeRequest(
      'DELETE',
      '/api/v1/recruiter-activity-tracker/assign-job',
      { jobId }
    );
  }

  static async fetchRecruiterAssigned(
    workspaceId: number,
    page: number = 1,
    selfhosted: boolean = false,
    status?: string,
    searchText?: string
  ) {
    const query = queryString.stringify(
      { page, selfhosted, status, searchText },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );
    return this.makeRequest('GET', `/api/v2/job/${workspaceId}?${query}`);
  }

  // Todo: Need to update as above
  static async updateJobStatus(jobId: number, status: string) {
    return axiosInstance({
      method: 'PATCH',
      url: `/api/v1/job/status/${jobId}`,
      data: {
        status,
      },
    });
  }

  static async addStakeholdersToJob(
    payload: Array<{ recruiterId: number; jobId: number }>
  ) {
    return axiosInstance({
      method: 'PUT',
      url: '/api/v1/job/add-stakeholder',
      data: payload,
    });
  }

  static async addStakeholdersToApplication(
    payload: Array<{ recruiterId: number; applicationId: number }>
  ) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v1/job-application/assign-recruiter',
      data: payload,
    });
  }

  static async fetchAssignedJobs() {
    return axiosInstance({
      method: 'GET',
      url: '/api/v1/job/recruiter-active-jobs',
    });
  }
}
