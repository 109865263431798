import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import CandidateWrapper from '../Candidate';
import { getCandidatesByPosting } from '../../redux/features/applications/applicationSlice';
import { useAppDispatch } from '../../hooks/redux';

const JobApplicants = () => {
  const { jhash = '' } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    requestJobApplicants();
  }, []);

  const requestJobApplicants = () => {
    //@ts-ignore
    dispatch(getCandidatesByPosting({ id: jhash, page: 1 }));
  };

  const handlePageChange = (page: number) => {
    //@ts-ignore
    dispatch(getCandidatesByPosting({ id: jhash, page }));
  };

  return (
    <Box p={2}>
      <CandidateWrapper handlePageChange={handlePageChange} />
    </Box>
  );
};

export default JobApplicants;
