import _ from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  addToDo,
  fetchToDo,
  updateToDo,
  deleteToDo,
} from '../../services/todo';
import { RootState } from '../../store';
import { logout } from '../onboarding/loginSlice';

export interface IToDoItem {
  task: string;
  id: number;
  status: string;
  deadline: number | Date;
  date?: Date;
}
export interface ITodo {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string;
  list: Array<IToDoItem>;
  total: number;
}

const initialState: ITodo = {
  loading: 'idle',
  error: '',
  list: [],
  total: 0,
};

export const sortListByTime = (list: Array<IToDoItem>) => {
  list.sort(
    (a, b) => Number(new Date(a.deadline)) - Number(new Date(b.deadline))
  );
  return list;
};

export const getTodoList = createAsyncThunk('dashboard/todo', async () => {
  const response = await fetchToDo();
  return response.data;
});

export const createToDo = createAsyncThunk(
  'dashboard/createToDo',
  async (todo: any, { dispatch }) => {
    const response = await addToDo(todo);
    dispatch(getTodoList());
    return response.data;
  }
);

export const editToDo = createAsyncThunk(
  'dashboard/editTod',
  async (todo: IToDoItem, { dispatch }) => {
    const response = await updateToDo(todo);
    dispatch(getTodoList());
    return response.data;
  }
);

export const removeToDo = createAsyncThunk(
  'dashboard/deleteToDo',
  async (id: number, { getState, dispatch }) => {
    const {
      todo: { list = [] },
    } = getState() as RootState;
    const todoItemsIndex = _.findIndex(list, function (o: any) {
      return o.id === id;
    });
    const response = await deleteToDo(list[todoItemsIndex]);
    dispatch(getTodoList());
    return response.data;
  }
);

export const todoSlice = createSlice({
  name: 'todo',
  initialState,
  reducers: {
    sortTodoList: (state, action) => {
      const todoList: any = action.payload;
      return {
        ...state,
        list: sortListByTime(todoList),
        total: todoList.length,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTodoList.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getTodoList.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.list = sortListByTime(action.payload);
      })
      .addCase(getTodoList.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(logout, (state) => {
        state.loading = 'idle';
        state.error = '';
        state.list = [];
        state.total = 0;
      });
    // .addCase(createToDo.pending, (state) => {
    //   state.loading = "pending";
    // })
    // .addCase(createToDo.fulfilled, (state, action) => {
    //   state.loading = "succeeded";
    // })
    // .addCase(createToDo.rejected, (state) => {
    //   state.loading = "failed";
    // })
    // .addCase(editToDo.pending, (state) => {
    //   state.loading = "pending";
    // })
    // .addCase(editToDo.fulfilled, (state, action) => {
    //   state.loading = "succeeded";
    // })
    // .addCase(editToDo.rejected, (state) => {
    //   state.loading = "failed";
    // })
    // .addCase(removeToDo.pending, (state) => {
    //   state.loading = "pending";
    // })
    // .addCase(removeToDo.fulfilled, (state, action) => {
    //   state.loading = "succeeded";
    // })
    // .addCase(removeToDo.rejected, (state) => {
    //   state.loading = "failed";
    // });
  },
});

export default todoSlice.reducer;
