import { createSlice } from '@reduxjs/toolkit';

export interface InitialState {
  isOpen: Array<string>;
  defaultId: string;
  opened: boolean;
}

const initialState: InitialState = {
  isOpen: [],
  defaultId: 'default',
  opened: false,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleMenu: (state, action) => {
      state.opened = action.payload;
    },
    setOpenMenu: (state, action) => {
      state.isOpen = [];
      state.isOpen.push(action.payload);
    },
  },
});

export const { toggleMenu, setOpenMenu } = layoutSlice.actions;

export default layoutSlice.reducer;
