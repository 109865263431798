import { useState } from 'react';
import { useTheme, styled, Theme } from '@mui/material/styles';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Menu,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Checkbox,
  tableCellClasses,
  TableBody,
  IconButton,
  TablePagination,
  Stack,
} from '@mui/material';
import dayjs from 'dayjs';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useNavigate } from 'react-router-dom';

import { ROOT_URL } from '../../utils/constants';
import { setCandidateDetails } from '../../redux/features/applications/applicationSlice';
import { useAppDispatch } from '../../hooks/redux';

//TODO: Use ApplicantList component in it
//@ts-ignore
import { Dimmer, Alert } from 'tabler-react';
import { connect } from 'react-redux';

import StyledTableCell from '../styled-component/Styled.Cell';
import StyledTableRow from '../styled-component/Styled.Row';

interface Props {
  handlePageChange: Function;
}

const CandidateWrapper = (props: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPage] = useState(0);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    props.handlePageChange(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (candidate: any) => {
    dispatch(setCandidateDetails(candidate));
    navigate(`/applicant?candidateId=${candidate.id}`);
  };

  const { list, total } = props.application.candidates;
  const { token = '' } = props.app;
  if (total > 0) {
    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                alignContent="center"
                justifyContent="space-between"
                paddingY={2}
              >
                <Grid item>
                  <Typography variant="h6">Total Candidates {total}</Typography>
                </Grid>
                <Grid item>
                  <MoreHorizOutlinedIcon
                    fontSize="small"
                    sx={{
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                    }}
                    aria-controls="menu-popular-card"
                    aria-haspopup="true"
                    onClick={handleClick}
                  />
                  <Menu
                    id="menu-popular-card"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    variant="selectedMenu"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem onClick={handleClose}> Today</MenuItem>
                    <MenuItem onClick={handleClose}> This Month</MenuItem>
                    <MenuItem onClick={handleClose}> This Year </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Candidate Information</StyledTableCell>
                  <StyledTableCell align="left">Opening Name</StyledTableCell>
                  <StyledTableCell align="left">Applied on</StyledTableCell>
                  <StyledTableCell align="left">Remark</StyledTableCell>
                  <StyledTableCell align="center">Attachment</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((candidate: any) => {
                  return (
                    <StyledTableRow
                      key={`candidate-${candidate.id}`}
                      hover
                      onClick={() => handleRowClick(candidate)}
                    >
                      <StyledTableCell component="th" scope="row">
                        <Stack direction={'row'} alignItems={'flex-start'}>
                          <Checkbox defaultChecked />
                          <Stack direction={'column'}>
                            <Typography variant="body2">
                              {candidate.name}
                            </Typography>
                            {candidate.email && (
                              <Typography
                                variant="body2"
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <EmailIcon
                                  color="primary"
                                  fontSize="inherit"
                                  sx={{ mr: 1 }}
                                />
                                {candidate.email}
                              </Typography>
                            )}
                            {candidate.mobile && (
                              <Typography
                                variant="body2"
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <PermPhoneMsgIcon
                                  fontSize="inherit"
                                  color="primary"
                                  sx={{ mr: 1 }}
                                />
                                {candidate.mobile}
                              </Typography>
                            )}
                          </Stack>
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell align="left">NA</StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography
                          variant="body2"
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <AccessTimeIcon
                            color="primary"
                            fontSize="inherit"
                            sx={{ mr: 1 }}
                          />
                          {dayjs(candidate.createdAt).format(
                            'MMM DD, YYYY hh:mm:ss A'
                          )}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {candidate.remark}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {candidate.cv ? (
                          <IconButton
                            aria-label="download"
                            color="primary"
                            LinkComponent={'a'}
                            target="_blank"
                            href={`${ROOT_URL}/api/resume?file=${candidate.cv}&&action=download&&auth=${token}`}
                          >
                            <DownloadIcon />
                          </IconButton>
                        ) : (
                          'NA'
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              '& .MuiTablePagination-selectLabel': {
                marginBottom: 0,
              },
              '& .MuiTablePagination-displayedRows': {
                marginBottom: 0,
              },
            }}
          />
        </CardContent>
      </Card>
    );
  } else if (total !== 0) {
    return (
      <Dimmer active loader>
        Getting candidates
      </Dimmer>
    );
  } else {
    return (
      <Alert type="primary" icon="alert-triangle">
        No candidate has applied yet!
      </Alert>
    );
  }
};

const mapStateToProps = (state: any) => ({ ...state });

export default connect<Props>(mapStateToProps)(CandidateWrapper);
