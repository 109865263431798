import React, { useState } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  Stack,
  Button,
  Box,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from 'lodash';

interface Props {
  label: string;
  value: number | '';
  onChange: (value: number | '') => void;
  helperText?: string | undefined;
  maxDigits?: number;
}

const NumericInput: React.FC<Props> = ({
  label = '',
  value = '',
  onChange,
  helperText = '',
  maxDigits = 2,
}) => {
  //   const [value, setValue] = useState<number | ''>('');

  //   // Increment the value
  const handleIncrement = () => {
    onChange(Number(value) + 1);
  };

  //   // Decrement the value
  const handleDecrement = () => {
    onChange(Math.max(Number(value) - 1, 0));
  };

  //   // Handle manual input changes with validation for numbers
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.replace(/[^0-9]/g, '');

    const parsedValue = Number(newValue);
    if (newValue == '') {
      onChange('');
      return;
    }

    const regex = new RegExp(`^\\d{0,${maxDigits}}$`);
    // Check if the input is a valid number (including empty string for clearing the field)
    if (_.isNumber(parsedValue)) {
      const value = parsedValue.toString().replace(/^0+/, '');

      if (regex.test(parsedValue.toString())) {
        onChange(Number(value));
      }
    } else {
      onChange(0);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'e' ||
      e.key === 'E' ||
      e.key === '+' ||
      e.key === '-' ||
      e.key === '.'
    ) {
      e.preventDefault();
    }
  };

  const textFieldValue =
    value && value.toString().length > 1
      ? value.toString().replace(/^0+/, '')
      : value;

  return (
    <TextField
      type="number"
      value={textFieldValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      variant="outlined"
      label={label}
      size="small"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Stack direction="column" spacing={0} alignItems="center">
              <Box
                onClick={handleIncrement}
                aria-label="increment"
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  height: 22,
                  cursor: 'pointer',
                }}
              >
                <ArrowDropUpIcon
                  onClick={handleIncrement}
                  aria-label="increment"
                />
              </Box>
              <Box
                onClick={handleDecrement}
                aria-label="decrement"
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  height: 22,
                  cursor: 'pointer',
                }}
              >
                <ArrowDropDownIcon />
              </Box>
            </Stack>
          </InputAdornment>
        ),
      }}
      inputProps={{
        inputMode: 'numeric',
        min: 0, // Restrict value to non-negative numbers
        pattern: '[0-9]*', // Mobile browsers support pattern for numeric input
      }}
      helperText={helperText}
    />
  );
};

export default NumericInput;
