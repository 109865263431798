import React from 'react';

import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  Stack,
  Tooltip,
  IconButton,
  Alert,
} from '@mui/material';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import _ from 'lodash';

import { ROOT_URL } from '../../utils/constants';

interface Props {
  template: Array<any>;
  feedbacks: Record<string, string>;
  candidateId: number | string;
}

const CandidateFeedback = ({
  template = [],
  feedbacks = {},
  candidateId = '',
}: Props) => {
  const renderFeedback = (feedbacks: any, column: string) => {
    let value = feedbacks[column] || '';
    switch (column) {
      case 'Total Experience':
        if (value) {
          value = Number(value);
          return `${Math.floor(value / 12)} years ${value % 12} months`;
        }
        return '';
      case 'Current CTC':
      case 'Expected CTC':
        if (value) {
          value = Number(value);
          return `${Math.floor(value / 100000)} lakh ${
            value % 100000
          } thousand`;
        }
        return '';
      case 'Notice Period':
        if (value) {
          return `${value} days`;
        }
        return '';
      default:
        return value;
    }
  };
  return _.isEmpty(feedbacks) ? (
    <Alert severity="warning">
      No feedback has been provided for this candidate.
    </Alert>
  ) : (
    <>
      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="subtitle1">Feedbacks</Typography>
        <Tooltip title="Download Feedback">
          <IconButton
            color="primary"
            href={`${ROOT_URL}/api/v1/job-applicant-evaluation/download-feedback/${candidateId}`}
          >
            <SimCardDownloadIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            {template.map((column: string, index: number) => (
              <TableCell key={`tbl-header-${column}`}>{column}</TableCell>
            ))}
            <TableCell key={'tbl-header-remark'}>Remark</TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              {template.map((column: string, index: number) => (
                <TableCell
                  key={`tbl-header-${column}-value`}
                  style={{ verticalAlign: 'top' }}
                >
                  {renderFeedback(feedbacks, column)}
                </TableCell>
              ))}
              <TableCell
                key={'tbl-header-remark'}
                style={{ verticalAlign: 'top' }}
              >
                {feedbacks['Remark'] || ''}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CandidateFeedback;
