import { Component } from 'react';
import AuthService from './AuthService';

export default function withAuth(AuthComponent: any) {
  const Auth = new AuthService();

  return class Authenticated extends Component {
    constructor(props: any) {
      super(props);

      this.state = {
        isLoading: true,
      };
    }

    componentDidMount() {
      this.setState({ isLoading: false });
    }

    render() {
      return (
        <AuthComponent
          {...this.props}
          auth={Auth}
          //@ts-ignore
          isLoading={this.state.isLoading}
        />
      );
    }
  };
}
