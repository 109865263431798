import { lazy } from 'react';

import SuspenseView from '../components/SuspenseView.react';
const Dashboard = lazy(() => import('../components/dashboard'));

export default function () {
  return (
    <SuspenseView>
      <Dashboard />
    </SuspenseView>
  );
}
