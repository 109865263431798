import axios from 'axios';

import { ROOT_URL } from '../../utils/constants';

export interface IRegisterPayload {
  type: 'REGISTER';
  name: string;
  email: string;
  password: string;
  terms: boolean;
}

export const getRegister = (data: IRegisterPayload) => {
  return axios({
    method: 'POST',
    baseURL: ROOT_URL,
    url: '/api/v1/auth/signup',
    data,
  });
};
