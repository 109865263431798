import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Stack,
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography,
  Button,
} from '@mui/material';

import { useAppSelector } from '../../hooks/redux';
import { getNameFromDomain } from '../../utils/helper-functions';
const base64Pattern = /([A-Za-z0-9+/=]{16,})$/;

const Breadcrumbs = () => {
  const location = useLocation();
  let pathnames = location.pathname.split('/').filter(Boolean);
  pathnames = pathnames.filter((path) => path.replace(base64Pattern, ''));
  const { selectedClient: { name = '' } = {} } = useAppSelector(
    (state) => state.client
  );

  const isShowClient =
    pathnames.length > 1 && name && pathnames[0] == 'channels';
  return (
    <Stack
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <MUIBreadcrumbs
        separator="›"
        aria-label="breadcrumb"
        sx={{ padding: 0, background: 'none' }}
      >
        {pathnames.map((path, index) => {
          const isLast = index === pathnames.length - 1;
          const pathTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          return isLast ? (
            <Typography
              component="span"
              key={index}
              color="inherit"
              textTransform="capitalize"
            >
              {path.replace(/[^a-zA-Z ]/g, ' ') || ''}
            </Typography>
          ) : (
            <Link
              underline="hover"
              key={index}
              color="inherit"
              href={`/#${pathTo}`}
              textTransform="capitalize"
            >
              {path.replace(/[^a-zA-Z0-9 ]/g, ' ') || ''}
            </Link>
          );
        })}
        {isShowClient && (
          <Button variant="outlined" size="small">
            {getNameFromDomain(name).toUpperCase()}
          </Button>
        )}
      </MUIBreadcrumbs>
    </Stack>
  );
};

export default Breadcrumbs;
