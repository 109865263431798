import { axiosInstance } from '../../utils/axios.instance';

export default class CommentAPI {
  static async addComment(data: any) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v1/comments',
      data,
    });
  }

  static async fetchComment(id: number) {
    return axiosInstance({
      method: 'GET',
      url: `/api/v1/comments/${id}`,
    });
  }

  static async updateComment(commentId: number, data: any) {
    return axiosInstance({
      method: 'PUT',
      url: `/api/v1/comments/${commentId}`,
      data,
    });
  }

  static async addlikeReactionOnComment(data: { commentId: number }) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v1/comments/like-comment',
      data,
    });
  }
}
