import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@mui/lab';
import { Box, Avatar, Tooltip, Typography, Paper, Stack } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';

import Task from './Task.react';
import { stringAvatar } from '../../utils/helper-functions';

interface Props {
  timeline: any;
}

export default (props: Props) => {
  return (
    <Box component={Paper} p={2} elevation={2}>
      <Stack direction="row" alignItems="center" gap={1}>
        <ViewTimelineOutlinedIcon fontSize="small" />
        <Typography variant="body1" fontWeight="bold">
          Timeline
        </Typography>
      </Stack>

      <Timeline sx={{ alignItems: 'flex-start' }}>
        {props.timeline.map((timeline: any, i: number) => (
          <TimelineItem
            key={`timeline-item-${i}`}
            sx={{
              minHeight: '40px',
              '&:before': {
                padding: 0,
                display: 'none',
              },
            }}
          >
            <TimelineSeparator>
              <TimelineDot sx={{ margin: 0, padding: 0 }}>
                {timeline?.event?.recruiter?.name ? (
                  <Tooltip title={timeline.event.recruiter.name}>
                    <Avatar
                      alt={timeline.event.recruiter.name}
                      sx={{
                        width: 20,
                        height: 20,
                        bgcolor: '#ad51db',
                        fontSize: 10,
                      }}
                      {...stringAvatar(timeline.event.recruiter.name)}
                    />
                  </Tooltip>
                ) : (
                  <Avatar
                    sx={{
                      width: 20,
                      height: 20,
                      bgcolor: '#ad51db',
                      fontSize: 10,
                    }}
                  >
                    <PersonIcon />
                  </Avatar>
                )}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ padding: '0 16px' }}>
              <Task event={timeline.event} />
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
};
