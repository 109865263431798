import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import CandidateAPI from '../../services/candidate.service';
import { fetchCandidateTimeline } from './timeline.slice';
import { candidateById } from '../applications/applicationSlice';

interface IState {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  actions: Array<any>;
  error: string;
}

const fetchRecruiterActions = createAsyncThunk(
  'recruiterActions/fetchRecruiterActions',
  async (id: number) => {
    const response: any = await CandidateAPI.fetchRecruiterAction(id);
    return response;
  }
);

const addRecruiterAction = createAsyncThunk(
  'recruiterActions/addRecruiterAction',
  async (payload: { event: any; candidateId: number }, { dispatch }) => {
    await CandidateAPI.saveRecruiterAction(payload.event, payload.candidateId);
    dispatch(fetchRecruiterActions(payload.candidateId));
    dispatch(fetchCandidateTimeline(payload.candidateId));
    if (
      [
        'reject',
        'nr',
        'offer',
        'interview-schedule',
        'shortlisted',
        'screen-review-accept',
        'screen-review-reject',
        'offer-rejected',
        'offer-accepted',
        'probation-completion-date',
        'joined',
      ].includes(payload.event.eventSulg)
    ) {
      dispatch(candidateById(payload.candidateId));
    }
  }
);

const shareProfileViaEmail = createAsyncThunk(
  'recruiterActions/addRecruiterAction',
  async (payload: { data: any; candidateId: number }, { dispatch }) => {
    await CandidateAPI.shareProfileViaEmail(payload.data);
    dispatch(fetchRecruiterActions(payload.candidateId));
    dispatch(fetchCandidateTimeline(payload.candidateId));
    if (payload.data.event.slug == 'clientReview') {
      dispatch(candidateById(payload.candidateId));
    }
  }
);

const initialState: IState = {
  loading: 'idle',
  actions: [],
  error: '',
};

const recruiterActionSlice = createSlice({
  name: 'recruiterActions',
  initialState,
  reducers: {
    resetActions: (state) => {
      state.loading = 'idle';
      state.actions = [];
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecruiterActions.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchRecruiterActions.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.actions = action.payload;
        state.error = '';
      })
      .addCase(fetchRecruiterActions.rejected, (state) => {
        state.loading = 'failed';
        state.error = '';
      });
  },
});

export default recruiterActionSlice.reducer;

export const { resetActions } = recruiterActionSlice.actions;

export { fetchRecruiterActions, addRecruiterAction, shareProfileViaEmail };
