import React from 'react';

import { Stack, Button, Typography } from '@mui/material';

import GithubAppLink from '../../redux/services/github-link.service';

const DownloadAppCTA = () => {
  return (
    <Stack direction="row" mt={2} flexWrap="wrap" gap={1}>
      <Button
        color="primary"
        sx={{ padding: '5px', minWidth: 30 }}
        onClick={() => GithubAppLink.getAppUpdateLink('macos')}
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          <img
            src="images/platform-icons/icons8-mac-logo-80.png"
            width="20"
            height="20"
            alt="download macOS app"
          />
          <Typography variant="caption" textTransform="unset">
            macOS
          </Typography>
        </Stack>
      </Button>
      <Button
        color="primary"
        sx={{ padding: '5px', minWidth: 30 }}
        onClick={() => GithubAppLink.getAppUpdateLink('windows')}
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          <img
            src="images/platform-icons/icons8-microsoft-80.png"
            width="20"
            height="20"
            alt="download Windows app"
          />
          <Typography variant="caption" textTransform="unset">
            Windows
          </Typography>
        </Stack>
      </Button>
      <Button color="primary" sx={{ padding: '5px', minWidth: 30 }}>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <img
            src="images/platform-icons/icons8-linux-96.png"
            width="20"
            height="20"
            alt="download Linux app"
          />
          <Typography variant="caption" textTransform="unset">
            Linux
          </Typography>
        </Stack>
      </Button>
    </Stack>
  );
};

export default DownloadAppCTA;
