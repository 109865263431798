import { Box, Typography, Divider } from '@mui/material';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <Divider />
      <Box justifyContent="center" display="flex" padding={2}>
        <Box
          sx={{
            maxWidth: '1236px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            paddingY: 4,
            paddingX: 2,
          }}
        >
          <Typography variant="subtitle2" color="rgb(100, 110, 115)">
            &copy; HireXL. {currentYear}, All rights reserved
          </Typography>
          <Typography
            variant="caption"
            textAlign="center"
            color="rgb(100, 110, 115)"
          >
            When you visit or interact with our sites, services or tools, we or
            our authorized service providers may use cookies for storing
            information to help provide you with a better, faster and safer
            experience and for marketing purposes.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
