import { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { fetchIpAddress } from '../redux/services/ipService';
import ParseableTransport from '../logger/parseable-transport';
import packageJson from '../../package.json';
import { parseableAxiosInstance } from '../utils/axios-parseable-instance';

const useGlobalEventListener = () => {
  const location = useLocation();
  const [ipAddress, setIpAddress] = useState(null);

  const captureClickEvent = useCallback(
    (event: any) => {
      let target = event.target;
      // Traverse up the DOM tree until we find an element with the data-component-name attribute
      while (target && !target.dataset.componentName) {
        target = target.parentElement;
      }
      let componentName = 'NA';
      if (target) {
        componentName = target.dataset.componentName;
      }

      const { innerText } = event.target;

      ParseableTransport.event({
        event: 'click',
        component: componentName,
        content: innerText,
        appVersion: packageJson.version,
        userAgent: navigator.userAgent,
        pathname: location.pathname,
        level: 'info',
        appId: 'HXLREC01',
        message: `User Clicked on ${componentName} component`,
      });
    },
    [location.pathname]
  );

  useEffect(() => {
    const getIpAddress = async () => {
      const ip = await fetchIpAddress();
      setIpAddress(ip);
    };

    getIpAddress();

    document.addEventListener('click', captureClickEvent);

    return () => {
      document.removeEventListener('click', captureClickEvent);
    };
  }, [captureClickEvent]);

  useEffect(() => {
    ParseableTransport.event({
      level: 'info',
      appId: 'HXLREC01',
      message: `User on ${location.pathname}`,
      event: 'pageview',
      pathname: location.pathname,
      appVersion: packageJson.version,
      userAgent: navigator.userAgent,
    });
  }, [location]);

  useEffect(() => {
    if (ipAddress) {
      //@ts-ignore
      parseableAxiosInstance.defaults.ipAddress = ipAddress;
    }
  }, [ipAddress]);
};

export default useGlobalEventListener;
