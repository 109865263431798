import { axiosInstance } from '../../utils/axios.instance';
import queryString from 'query-string';

export class ReportAPI {
  static async columnFilterOptions(workspaceId: number, recruiterId: number) {
    return axiosInstance({
      method: 'GET',
      url: `/api/v1/report-engine/filter-options?recruiter=${recruiterId}&workspaceId=${workspaceId}`,
    });
  }

  static async getWorkDoneReport(
    channelId: number,
    start: string,
    end: string,
    page: 1
  ) {
    const query = queryString.stringify(
      {
        start,
        end,
        page,
      },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );
    return axiosInstance({
      method: 'GET',
      url: `/api/v1/report-engine/work-done/${channelId}?${query}`,
    });
  }
}
