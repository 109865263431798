import { lazy } from 'react';

import BaseLayout from '../components/layout/base-layout';
import SuspenseView from '../components/SuspenseView.react';

const LandingPage = lazy(() => import('../pages/landing/Landing.page'));
const JobsPage = lazy(() => import('../pages/Jobs.page'));

const LandingRoutes = {
  path: '/',
  element: <BaseLayout />,
  children: [
    {
      path: '/',
      element: (
        <SuspenseView>
          <LandingPage />
        </SuspenseView>
      ),
    },
    {
      path: '/open-positions',
      element: (
        <SuspenseView>
          <JobsPage />
        </SuspenseView>
      ),
    },
  ],
};

export default LandingRoutes;
